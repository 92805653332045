import React, { useEffect, useState } from "react";
import { IconButton, Button as MuiButton, Tooltip } from "@mui/material";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { z } from "zod";
import Caption1 from "../UI/Text/Caption/Caption1";
import { useToast } from "../UI/Toast/use-toast";
import {
  APPROVE_REFUND,
  GET_REFUND_APPROVALS,
  REJECT_REFUND,
} from "../../graphql/queries/refund";
import { DateTimePicker } from "../UI/shadcn/Time/date-time-picker";
import Button from "../UI/Button/Button";
import dayjs from "dayjs";
import ActionsCell from "../UI/ActionCell";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import {
  getRefundReasons,
  getRefundStatuses,
  getRefundTypes,
} from "../../app/financeSlice";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { Checkbox } from "../UI/shadcn/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../UI/shadcn/dialog";
import Body1 from "../UI/Text/Body/Body1";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import { UserAccount } from "@/src/types/types";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { toast as toastStackable } from "sonner";
import { numberToMoney } from "../../utils/financialHelpers";
import {
  GetRefundApprovalsQuery,
  ListAllOperations,
  useApproveRefundMutation,
  useGetRefundApprovalsLazyQuery,
  useRejectRefundMutation,
} from "../../generated/graphql";
import {
  displayAlertError,
  displayAlertInfo,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../app/globalSlice";

type RefundApprovalType = {
  id: number;
  status: {
    name: string;
    id: number;
  };
  type: {
    name: string;
    id: number;
  };
  reason: {
    name: string;
    id: number;
  };
  amount: number;
  itemId: number;
  requestedBy: UserAccount;
  requestedDatetime: string;
  txnId: null | string;
  approvedDatetime: null | string;
  approvedBy: UserAccount;
  notes: string;
};

type ApprovalResponseReason = {
  id: number;
  reason: string;
};
type ApprovalResponseSuccess = {
  id: number;
  type: number;
  amount: number;
};

type RefundApprovalData = {
  success: boolean;
  message: string;
  invalidRefunds: ApprovalResponseReason[];
  failedRefunds: ApprovalResponseReason[];
  successfulRefunds: ApprovalResponseSuccess[];
};

const options: { id: number; text: string }[] = [
  { id: 0, text: "Approve" },
  { id: 1, text: "Reject" },
];

const RefundApprovalFilterSchema = z
  .object({
    startDate: z.date().optional(),
    endDate: z.date().optional(),
    itemId: z.number().optional(),
    statusId: z.number().optional(),
    typeId: z.number().optional(),
    reasonId: z.number().optional(),
  })
  .refine(
    (data) => {
      return (
        data.endDate !== undefined &&
        data.startDate !== undefined &&
        data.endDate > data.startDate
      );
    },
    { message: "End date must occur after start date" }
  );

type RefundApprovalFilterType = z.infer<typeof RefundApprovalFilterSchema>;

const RefundApproval: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, refundReasons, refundTypes, refundStatuses }: any =
    useSelector((state: RootState) => state.finance);

  const [searchParams] = useSearchParams();
  const itemId = searchParams.get("itemId")
    ? Number(searchParams.get("itemId"))
    : undefined;
  const [selectedRefunds, setSelectedRefunds] = useState<number[]>([]);
  const [singleSelected, setSingleSelected] = useState<boolean>(false);
  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState<boolean>(false);
  const [summaryDialogData, setSummaryDialogData] =
    useState<RefundApprovalData>({
      success: false,
      message: "",
      invalidRefunds: [],
      failedRefunds: [],
      successfulRefunds: [],
    });
  const [refundApprovalFilter, setRefundApprovalFilter] =
    useState<RefundApprovalFilterType>({
      startDate: dayjs().subtract(1, "week").toDate(),
      endDate: new Date(),
      itemId: itemId,
      reasonId: undefined,
      statusId: 1,
      typeId: undefined,
    });
  const [refundApprovalData, setRefundApprovalData] = useState<
    GetRefundApprovalsQuery["getRefundApprovals"]
  >([]);

  const [getRefundApprovals, { data, loading, error }] =
    useGetRefundApprovalsLazyQuery({
      fetchPolicy: "network-only",
    });

  const [approveRefund, { loading: processingRefunds }] =
    useApproveRefundMutation({
      refetchQueries: [ListAllOperations.Query.GetRefundApprovals],
    });

  const [rejectRefund, { loading: processingRefundRejection }] =
    useRejectRefundMutation({
      refetchQueries: [ListAllOperations.Query.GetRefundApprovals],
    });

  async function handleGetRefundApprovals(getType: number) {
    await getRefundApprovals({
      variables: {
        refundApprovalFilter: {
          ...refundApprovalFilter,
          startDate:
            refundApprovalFilter.startDate ??
            dayjs().subtract(1, "week").toDate(),
          endDate: refundApprovalFilter.endDate ?? new Date(),
        },
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.getRefundApprovals) {
          setRefundApprovalData(data.getRefundApprovals);
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError("Error occurred"));
      },
    });
  }

  async function handleApproveRefund() {
    await approveRefund({
      variables: {
        ids: selectedRefunds,
      },
      onCompleted: (data) => {
        if (data) {
          if (data.approveRefund.success) {
            dispatch(displayAlertSuccess("All refunds approved"));
          } else if (data.approveRefund.message) {
            dispatch(displayAlertWarning(data.approveRefund.message));
          } else {
            dispatch(displayAlertWarning("Some refunds were not approved"));
          }
          setSelectedRefunds([]);
          setOpenSummaryDialog(true);
          setSummaryDialogData(data.approveRefund);
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
    });
  }

  async function handleRejectRefund(id: number) {
    const res = await rejectRefund({
      variables: {
        id: id,
      },
    });
    if (res) {
      if (res.data) {
        if (res.data.rejectRefund.success) {
          dispatch(displayAlertSuccess(res.data.rejectRefund.message));
        } else {
          dispatch(displayAlertError(res.data.rejectRefund.message));
        }
      }
    } else {
      dispatch(displayAlertError("Something went wrong"));
    }
  }

  const handleSelectedOption = (
    row: GetRefundApprovalsQuery["getRefundApprovals"][0],
    optionId: number
  ) => {
    if (optionId === 0) {
      setSelectedRefunds([row.id]);
      setOpenApproveDialog(true);
      setSingleSelected(true);
    } else if (optionId === 1) {
      handleRejectRefund(row.id);
    }
  };

  useEffect(() => {
    getRefundApprovals({
      variables: {
        refundApprovalFilter: {
          ...refundApprovalFilter,
          startDate:
            refundApprovalFilter.startDate ??
            dayjs().subtract(1, "week").toDate(),
          endDate: refundApprovalFilter.endDate ?? new Date(),
        },
      },
      onCompleted: (data) => {
        if (data.getRefundApprovals) {
          setRefundApprovalData(data.getRefundApprovals);
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError("Error occurred"));
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });
  }, []);

  useEffect(() => {
    // Filters
    dispatch(getRefundReasons());
    dispatch(getRefundStatuses());
    dispatch(getRefundTypes());
  }, [dispatch]);

  const columnRefundApproval: ColumnDef<
    GetRefundApprovalsQuery["getRefundApprovals"][0]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setSelectedRefunds(
              value
                ? refundApprovalData
                    .filter((row) => row.status.id === 1)
                    .map((row) => row.id)
                : []
            );
            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = row.original.status.id === 1 && !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <>
            {row.original.status.id === 1 ? (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  if (value) {
                    setSelectedRefunds((refunds) => [
                      ...refunds,
                      row.original.id,
                    ]);
                  } else {
                    setSelectedRefunds((prevSelectedRefunds) =>
                      prevSelectedRefunds.filter((id) => id !== row.original.id)
                    );
                  }
                  row.toggleSelected(!!value);
                }}
                disabled={row.original.status.id !== 1}
                aria-label="Select row"
              />
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorFn: (row) => row.status.name || "N/A",
      id: "status",
      header: "Status",
    },
    {
      accessorFn: (row) =>
        row.shoppingCartItem.shoppingCart.user.firstName +
        " " +
        row.shoppingCartItem.shoppingCart.user.lastName,
      id: "user",
      header: "Player",
    },
    {
      accessorFn: (row) =>
        row.shoppingCartItem.session
          ? row.shoppingCartItem.session.registrationBatch.name
          : row.shoppingCartItem.tournament
          ? "No reg batch for tournament"
          : "N/A",
      id: "registrationBatch",
      header: "Reg Batch",
    },
    {
      accessorFn: (row) =>
        row.shoppingCartItem.session
          ? row.shoppingCartItem.session.league.name
          : row.shoppingCartItem.tournament
          ? row.shoppingCartItem.tournament.name
          : "N/A",
      id: "league",
      header: "League Name",
    },
    // User
    // Reg Batch
    // League name
    {
      accessorFn: (row) => `${numberToMoney(row.amount)}` || "N/A",
      id: "amount",
      header: "Amount",
    },
    {
      accessorFn: (row) => row.type.name || "N/A",
      id: "type",
      header: "Type",
    },
    {
      accessorFn: (row) =>
        `${row.requestedBy.firstName} ${row.requestedBy.lastName}` || "N/A",
      id: "requestedBy",
      header: "Requested By",
    },
    {
      accessorFn: (row) =>
        dayjs(row.requestedDatetime).format("YYYY-MM-DD HH:mm") || "N/A",
      id: "requestedDatetime",
      header: "Requested At",
    },
    {
      accessorFn: (row) =>
        `${
          row.approvedBy
            ? `${row.approvedBy?.firstName} ${row.approvedBy?.lastName}`
            : "N/A"
        }`,
      id: "approvedBy",
      header: "Approved By",
    },
    {
      accessorFn: (row) =>
        row.approvedDatetime
          ? dayjs(row.approvedDatetime).format("YYYY-MM-DD HH:mm")
          : "N/A",
      id: "approvedDatetime",
      header: "Approved At",
    },
    {
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center justify-center">
            <div className="p-2">
              <Dialog>
                <DialogTrigger
                  asChild
                  disabled={row.original.notes === null}
                >
                  <IconButton>
                    <GradingOutlinedIcon
                      className={
                        row.original.notes === null
                          ? "text-neutral-60"
                          : "text-primary-30"
                      }
                    />
                  </IconButton>
                </DialogTrigger>
                <DialogContent className="w-1/2 max-w-full">
                  <DialogHeader>
                    <DialogTitle>Refund Notes</DialogTitle>
                  </DialogHeader>
                  <DialogDescription>
                    <div className="flex flex-col w-full gap-4">
                      <Body1 className="break-all">{row.original.notes}</Body1>
                    </div>
                  </DialogDescription>
                  <DialogClose>
                    <Button variant={"negative"}>Close</Button>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        );
      },
      header: "Notes",
    },
    {
      cell: ({ row }) => {
        return (
          <>
            {options && row.original.status.id === 1 && (
              <ActionsCell
                row={row.original}
                options={options}
                handleSelectedOption={(event, optionId) =>
                  handleSelectedOption(row.original, optionId)
                }
              />
            )}
          </>
        );
      },
      header: "Actions",
    },
  ];

  if (loading) return <LoadingDialog open={loading} />;
  if (error) return <div>Something went wrong</div>;

  return (
    <main className="flex flex-col justify-between w-full gap-4">
      {processingRefunds ||
        (processingRefundRejection && <LoadingDialog open={true} />)}
      <Headline1Variable>Refund Approvals</Headline1Variable>
      <div className="flex flex-col gap-2 w-fit">
        <div className="grid items-end grid-cols-3 gap-4 ">
          <FormFieldSelect
            inputChange={(value) => {
              setRefundApprovalFilter({
                ...refundApprovalFilter,
                statusId: +value,
              });
            }}
            label={"Status"}
            placeholder=""
            value={
              refundApprovalFilter.statusId
                ? refundApprovalFilter.statusId.toString()
                : "0"
            }
          >
            {[{ id: 0, name: "All Statuses" }, ...refundStatuses]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setRefundApprovalFilter({
                ...refundApprovalFilter,
                typeId: +value,
              });
            }}
            label={"Type"}
            placeholder=""
            value={
              refundApprovalFilter.typeId
                ? refundApprovalFilter.typeId.toString()
                : "0"
            }
          >
            {[{ id: 0, name: "All Types" }, ...refundTypes]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setRefundApprovalFilter({
                ...refundApprovalFilter,
                reasonId: +value,
              });
            }}
            label={"Reason"}
            placeholder=""
            value={
              refundApprovalFilter.reasonId
                ? refundApprovalFilter.reasonId.toString()
                : "0"
            }
          >
            {[{ id: 0, name: "All Reasons" }, ...refundReasons]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-row items-end w-full gap-4">
          <div className="flex flex-col gap-1">
            <Caption1 className="pl-3 font-medium">Start Date</Caption1>
            <DateTimePicker
              date={refundApprovalFilter.startDate}
              setDate={(date) =>
                setRefundApprovalFilter({
                  ...refundApprovalFilter,
                  startDate: date,
                })
              }
              disabled={refundApprovalFilter.statusId === 1}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Caption1 className="pl-3 font-medium">End Date</Caption1>
            <DateTimePicker
              date={refundApprovalFilter.endDate}
              setDate={(date) =>
                setRefundApprovalFilter({
                  ...refundApprovalFilter,
                  endDate: date,
                })
              }
              disabled={refundApprovalFilter.statusId === 1}
            />
          </div>
          <Button
            variant="primary"
            className="w-[280px] h-fit"
            onClick={() => handleGetRefundApprovals(1)}
          >
            Apply
          </Button>
        </div>
        <div className="flex flex-row items-end w-full gap-4">
          <Button
            className="w-[280px] h-fit"
            variant={
              selectedRefunds.length > 0 && !singleSelected
                ? "primary"
                : "disabled"
            }
            disabled={!selectedRefunds.length}
            onClick={() => setOpenApproveDialog(true)}
          >
            Approve All Selected
          </Button>
          <Button
            className="w-[280px] h-fit"
            variant={
              summaryDialogData.successfulRefunds.length > 0 ||
              summaryDialogData.invalidRefunds.length > 0 ||
              summaryDialogData.failedRefunds.length > 0
                ? "secondary"
                : "disabled"
            }
            disabled={
              summaryDialogData.successfulRefunds.length > 0 ||
              summaryDialogData.invalidRefunds.length > 0 ||
              summaryDialogData.failedRefunds.length > 0
                ? false
                : true
            }
            onClick={() => setOpenSummaryDialog(true)}
          >
            Review recent approval
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {refundApprovalData && refundApprovalData.length > 0 && (
          <div className="flex flex-col gap-4">
            <DataTable
              data={refundApprovalData}
              columns={columnRefundApproval}
            />
            <Dialog
              open={openApproveDialog}
              onOpenChange={(value) => {
                if (singleSelected && value === false) {
                  setSelectedRefunds([]);
                  setSingleSelected(false);
                }
                setOpenApproveDialog(value);
              }}
            >
              <DialogContent className="max-w-96">
                <DialogHeader>
                  <DialogTitle>Refund Confirmation</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col gap-4">
                    <Body1>
                      You&apos;re about to approve the refunds for{" "}
                      {selectedRefunds.length} items. With a total value of{" "}
                      {refundApprovalData
                        .filter((refund) => selectedRefunds.includes(refund.id))
                        .reduce(
                          (sum, refund, index) =>
                            (sum += Number(refund.amount)),
                          0
                        )
                        .toFixed(2)}
                    </Body1>
                  </div>
                </DialogDescription>
                <DialogClose>
                  <div className="flex flex-row gap-8 justify-evenly">
                    <Button
                      variant={"primary"}
                      onClick={() => handleApproveRefund()}
                    >
                      Approve
                    </Button>
                    <Button variant={"negative"}>Close</Button>
                  </div>
                </DialogClose>
              </DialogContent>
            </Dialog>
            <Dialog
              open={openSummaryDialog}
              onOpenChange={setOpenSummaryDialog}
            >
              <DialogContent className="max-w-[800px] max-h-[600px] h-[600px] overflow-scroll">
                <DialogHeader>
                  <DialogTitle>Refund Summary</DialogTitle>
                </DialogHeader>
                <DialogDescription className="text-black">
                  <div className="flex flex-col gap-4 ">
                    {summaryDialogData.message !== "" && (
                      <>
                        <Body1>
                          An error occurred when processing the refunds. All
                          transactions that occured are recorded below.
                        </Body1>
                        <Body1>
                          Error message: {summaryDialogData.message}
                        </Body1>
                      </>
                    )}
                    <div className="flex flex-col gap-4">
                      <Headline2Variable>Successful Refunds</Headline2Variable>
                      <div className="flex flex-col gap-2">
                        <Subtitle1>Credit Card</Subtitle1>
                        <Body1>
                          {
                            summaryDialogData.successfulRefunds.filter(
                              (refund) => refund.type === 1
                            ).length
                          }{" "}
                          credit card refunds in the amount of $
                          {summaryDialogData.successfulRefunds
                            .filter((refund) => refund.type === 1)
                            .reduce((sum, item) => {
                              return sum + item.amount;
                            }, 0)
                            .toFixed(2)}
                        </Body1>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Subtitle1>Credit</Subtitle1>
                        <Body1>
                          {
                            summaryDialogData.successfulRefunds.filter(
                              (refund) => refund.type === 2
                            ).length
                          }{" "}
                          credit refunds in the amount of $
                          {summaryDialogData.successfulRefunds
                            .filter((refund) => refund.type === 2)
                            .reduce((sum, item) => {
                              return sum + item.amount;
                            }, 0)
                            .toFixed(2)}
                        </Body1>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Headline2Variable>Invalid Refunds</Headline2Variable>
                      <div className="flex flex-col gap-2">
                        {summaryDialogData.invalidRefunds.map(
                          (invalidRefund) => {
                            return (
                              <Body1>
                                Id: {invalidRefund.id} - Reason:{" "}
                                {invalidRefund.reason}
                              </Body1>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Headline2Variable>Failed Refunds</Headline2Variable>
                      <div className="flex flex-col gap-2">
                        {summaryDialogData.failedRefunds.map((failedRefund) => {
                          return (
                            <Body1>
                              Id: {failedRefund.id} - Reason:{" "}
                              {failedRefund.reason}
                            </Body1>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </DialogDescription>
                <DialogClose>
                  <div className="flex flex-row gap-8 justify-evenly">
                    <Button variant={"negative"}>Close</Button>
                  </div>
                </DialogClose>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    </main>
  );
};

export default RefundApproval;
