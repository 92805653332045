import React, { useEffect, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Headline2 from "../UI/Text/Headline/Headline2";
import Body1 from "../UI/Text/Body/Body1";
import { SwitchButton } from "../UI";
import Button from "../UI/Button/Button";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Checkbox from "../UI/FormControls/Checkbox";
import Card from "../UI/Card/Card";
import { useToast } from "../UI/Toast/use-toast";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SPORTS_BY_USERID } from "../../graphql/queries";
import { UPDATE_USER_NOTIFICATION_SETTINGS } from "../../graphql/queries/user";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import {
  UserByIdGetQuery,
  useSportsByUserIdQuery,
  useUpdateNotificationSettingsMutation,
} from "../../../src/generated/graphql";
import { displayAlertSuccess } from "../../app/globalSlice";

type notificationFormData = {
  marketingEmail: boolean;
  scoreReminderEmail: boolean;
  gameReminderEmail: boolean;
  registrationOpeningEmail: boolean;
  sportSpecificEmail: boolean;
  [sportId: number]: boolean;
  teamChatEmail: boolean;
  gameChatEmail: boolean;
};

const Notification = ({ user }: { user: UserByIdGetQuery["userByIdGet"] }) => {
  const [formData, setFormData] = useState<notificationFormData>({
    marketingEmail: false,
    scoreReminderEmail: false,
    gameReminderEmail: false,
    registrationOpeningEmail: false,
    sportSpecificEmail: false,
    teamChatEmail: false,
    gameChatEmail: false,
  });
  const [sportList, setSportList] = useState<{ id: string; name: string }[]>(
    []
  );
  const dispatch = useDispatch<AppDispatch>();

  const { loading: loadingSportByUserId } = useSportsByUserIdQuery({
    variables: {
      userId: user.id,
    },
    onCompleted: (data) => {
      setSportList(data.sportsByUserId);
    },
  });

  const [UpdatedUserInfo, { loading: loadingUpdate }] =
    useUpdateNotificationSettingsMutation();

  useEffect(() => {
    const sportEmailIds = user.sportEmailIds;
    const sportEmails: { [id: number]: boolean } = {};
    sportEmailIds?.forEach((sportId: number) => {
      sportEmails[sportId] = true;
    });
    setFormData({
      marketingEmail: user.marketingEmail || false,
      scoreReminderEmail: user.scoreReminderEmail || false,
      gameReminderEmail: user.gameReminderEmail || false,
      registrationOpeningEmail: user.registrationOpeningEmail || false,
      sportSpecificEmail: user.sportSpecificEmail || false,
      teamChatEmail: user.teamChatEmail || false,
      gameChatEmail: user.gameChatEmail || false,
      ...sportEmails,
    });
  }, [user]);

  const handleUpdateProfile = () => {
    const formDataKeys = Object.keys(formData);

    const sportEmailIds: number[] = [];
    formDataKeys.forEach((key) => {
      if (parseInt(key) > 0 && formData[parseInt(key)] === true) {
        sportEmailIds.push(parseInt(key));
      }
    });

    UpdatedUserInfo({
      variables: {
        id: user.id,
        marketingEmail: formData.marketingEmail,
        scoreReminderEmail: formData.scoreReminderEmail,
        gameReminderEmail: formData.gameReminderEmail,
        registrationOpeningEmail: formData.registrationOpeningEmail,
        sportSpecificEmail: formData.sportSpecificEmail,
        sportEmailIds: sportEmailIds,
        teamChatEmail: formData.teamChatEmail,
        gameChatEmail: formData.gameChatEmail,
      },
    }).then(async (data: any) => {
      const sportEmailIds =
        data.data.updateUserAccount.userAccount.sportEmailIds;
      const sportEmails: { [id: number]: boolean } = {};
      sportEmailIds.forEach((sportId: number) => {
        sportEmails[sportId] = true;
      });

      setFormData((prevState) => ({
        ...prevState,
        marketingEmail: data.data.updateUserAccount.userAccount.marketingEmail,
        scoreReminderEmail:
          data.data.updateUserAccount.userAccount.scoreReminderEmail,
        gameReminderEmail:
          data.data.updateUserAccount.userAccount.gameReminderEmail,
        registrationOpeningEmail:
          data.data.updateUserAccount.userAccount.registrationOpeningEmail,
        sportSpecificEmail:
          data.data.updateUserAccount.userAccount.sportSpecificEmail,
        teamChatEmail: data.data.updateUserAccount.userAccount.teamChatEmail,
        gameChatEmail: data.data.updateUserAccount.userAccount.gameChatEmail,
        ...sportEmails,
      }));
      dispatch(displayAlertSuccess("Notification Settings Updated"));
    });
  };

  if (loadingUpdate || loadingSportByUserId) {
    return <div>Loading...</div>;
  }

  return (
    <main className="flex justify-center min-h-screen bg-surface-bg2">
      <div className="flex flex-col w-full max-w-5xl p-4">
        <Card className="mt-5">
          <div className="flex flex-row justify-between w-full gap-3 mb-4">
            <div>
              <Headline2 size="s-xs">Game & Attendance Reminders</Headline2>
              <Body1>
                We'll send you a reminder email three days before each game with
                the option to set your attendance. Setting your attendance for
                games helps your team know who’s in and find subs if needed.
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.gameReminderEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  gameReminderEmail: value.target.checked,
                });
              }}
            />
          </div>
          <div className="flex flex-row justify-between w-full gap-3 mb-4">
            <div>
              <Headline2 size="s-xs">Score Reminders</Headline2>
              <Body1>
                The morning after your game we’ll send an email to remind you to
                submit the score, if no game score has been recorded yet.
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.scoreReminderEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  scoreReminderEmail: value.target.checked,
                });
              }}
            />
          </div>
          <div className="flex flex-row justify-between w-full gap-3 mb-4">
            <div>
              <Headline2 size="s-xs">Team Chat Notifications</Headline2>
              <Body1>
                We’ll email you at 5 PM anytime you have unread team chat
                messages.
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.teamChatEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  teamChatEmail: value.target.checked,
                });
              }}
            />
          </div>
          <div className="flex flex-row justify-between w-full gap-3 mb-4">
            <div>
              <Headline2 size="s-xs">Game Chat Notifications</Headline2>
              <Body1>
                We’ll email you at 5 PM anytime you have unread game chat
                messages.
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.gameChatEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  gameChatEmail: value.target.checked,
                });
              }}
            />
          </div>
        </Card>
        <Card className="mt-5">
          <div className="flex flex-row justify-between w-full gap-3 mb-4">
            <div>
              <Headline2 size="s-xs">Exclusive Offers & Player Perks</Headline2>
              <Body1>
                Be the first to know about exclusive discounts and partner
                offers made just for our players.
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.marketingEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  marketingEmail: value.target.checked,
                });
              }}
            />
          </div>
          <div className="flex flex-row justify-between w-full gap-3 mb-4">
            <div>
              <Headline2 size="s-xs">Registration Opening Alerts</Headline2>
              <Body1>
                Be the first to know when registration opens, so you can
                register as soon as a new season goes live.
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={formData.registrationOpeningEmail}
              handleChange={(value) => {
                setFormData({
                  ...formData,
                  registrationOpeningEmail: value.target.checked,
                });
              }}
            />
          </div>
        </Card>
        <Card className="mt-5">
          <div className="flex flex-col gap-4 md:items-start ">
            <div className="flex flex-row justify-between w-full gap-3 mb-4">
              <div>
                <Headline2 size="s-xs">Sport Specific Updates</Headline2>
                <Body1>
                  Get the latest news and updates for leagues and special events
                  in your favorite sports.
                </Body1>
              </div>
              <SwitchButton
                labelStart=""
                labelEnd=""
                height="30"
                width="50"
                checked={formData.sportSpecificEmail}
                handleChange={(value) => {
                  setFormData((prev) => {
                    const newFormData = { ...prev };
                    sportList.forEach((sport) => {
                      newFormData[parseInt(sport.id)] = false;
                    });
                    return {
                      ...newFormData,
                      sportSpecificEmail: value.target.checked,
                    };
                  });
                }}
              />
            </div>
            <div className="flex-col px-8">
              <div className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {sportList.map((sport) => {
                  return (
                    <div key={sport.id}>
                      <Checkbox
                        label={sport.name}
                        defaultChecked={false}
                        checkedState={
                          formData["sportSpecificEmail"] &&
                          formData[parseInt(sport.id)]
                        }
                        inputChange={(val) => {
                          setFormData({
                            ...formData,
                            [parseInt(sport.id)]: val,
                            sportSpecificEmail: true,
                          });
                        }}
                        id={sport.id}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
        <Card className="mt-5">
          <div className="flex flex-row justify-between w-full mb-4">
            <div>
              <Headline2 size="s-xs">Unsubscribe from all emails</Headline2>
              <Body1>
                No game reminders, score reminders, promotional email or any
                other emails
              </Body1>
            </div>
            <SwitchButton
              labelStart=""
              labelEnd=""
              height="30"
              width="50"
              checked={
                !formData.marketingEmail &&
                !formData.scoreReminderEmail &&
                !formData.gameReminderEmail &&
                !formData.registrationOpeningEmail &&
                !formData.sportSpecificEmail &&
                !formData.teamChatEmail &&
                !formData.gameChatEmail
              }
              handleChange={(value) => {
                setFormData({
                  marketingEmail: false,
                  scoreReminderEmail: false,
                  gameReminderEmail: false,
                  registrationOpeningEmail: false,
                  sportSpecificEmail: false,
                  teamChatEmail: false,
                  gameChatEmail: false,
                });
              }}
            />
          </div>
        </Card>
        {/* Button for Update Profile*/}
        <div className="mt-6">
          <Button
            variant="primary"
            onClick={handleUpdateProfile}
            type="submit"
          >
            Save
          </Button>
        </div>
        <LoadingDialog open={loadingUpdate} />
      </div>
    </main>
  );
};

export default Notification;
