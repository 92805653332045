import { gql } from "@apollo/client";

const CREATE_TEAM = gql`
  mutation TeamCreate($createTeamArgs: [TeamArgs]) {
    teamsCreate(createTeamArgs: $createTeamArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation TeamUpdate($updateTeamArgs: [TeamUpdateArgs]) {
    teamsUpdate(updateTeamArgs: $updateTeamArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_TEAM_STANDINGS = gql`
  mutation UpdateTeamStandings($updateTeamStandings: [TeamStandingsArgs]!) {
    updateTeamStandings(updateTeamStandings: $updateTeamStandings) {
      code
      message
      success
    }
  }
`;

const DELETE_TEAM = gql`
  mutation TeamDelete($teamDeleteId: Int!) {
    teamDelete(id: $teamDeleteId) {
      code
      message
      success
    }
  }
`;

const TEAM_BY_USERID = gql`
  query TeamsByUserId($userId: ID!) {
    teamsByUserId(userId: $userId) {
      id
      registrant {
        id
      }
      teamPlayers {
        updatedAt
        createdAt
        team {
          session {
            region {
              id
              name
            }
            registrationBatch {
              id
              name
            }
            league {
              id
              name
            }
            dayOfWeek
          }
          teamPlayers {
            teamPlayerRole {
              id
              teamPlayerRoleName
            }
          }
          isIndyTeam
        }
      }
      name
    }
  }
`;

const GET_NUMBER_OF_PLAYERS_ON_TEAMS = gql`
  query GetNumberOfPlayersOnTeams($teamIds: [Int!]!) {
    getNumberOfPlayersOnTeams(teamIds: $teamIds)
  }
`;

const CHECK_REPEATED_TEAM_NAMES = gql`
  query TeamNameCheck($teamNames: [String], $sessionId: Int) {
    teamNameCheck(teamNames: $teamNames, sessionId: $sessionId)
  }
`;

const GET_ALL_TEAM_PLAYER_ROLES = gql`
  query TeamPlayerRoles {
    teamPlayerRoles {
      id
      teamPlayerRoleName
    }
  }
`;

const GET_TEAMS_BY_IDS = gql`
  query TeamsByIds($teamIds: [Int]!) {
    teamsByIds(teamIds: $teamIds) {
      id
      name
    }
  }
`;

const GET_TEAM_BY_ID = gql`
  query TeamById($id: Int!) {
    team(id: $id) {
      id
      name
      teamPlayers {
        id
        playerUserProfile {
          id
        }
        teamPlayerRole {
          id
          teamPlayerRoleName
        }
      }
    }
  }
`;

gql`
  query TeamHq($id: Int!) {
    team(id: $id) {
      id
      name
      teamCaptain {
        playerUserProfile {
          firstName
        }
      }
      session {
        id
        league {
          id
          name
        }
      }
      division {
        id
        name
      }
      schedule {
        id
      }
      chatRoom {
        id
        channel
        createdAt
        chatMessages {
          message
          userId
          id
          createdAt
        }
      }
      teamPlayers {
        id
        playerUserProfile {
          id
          firstName
          lastName
        }
        teamPlayerRole {
          id
          teamPlayerRoleName
        }
      }
      deletedTeamPlayers {
        id
        playerUserProfile {
          id
          firstName
          lastName
        }
        teamPlayerRole {
          id
          teamPlayerRoleName
        }
      }
    }
  }
`;

export {
  CREATE_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  TEAM_BY_USERID,
  UPDATE_TEAM_STANDINGS,
  GET_NUMBER_OF_PLAYERS_ON_TEAMS,
  CHECK_REPEATED_TEAM_NAMES,
  GET_ALL_TEAM_PLAYER_ROLES,
  GET_TEAMS_BY_IDS,
  GET_TEAM_BY_ID,
};
