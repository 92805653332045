import React, { useEffect, useState } from "react";
import { IconButton, Button as MuiButton, Tooltip } from "@mui/material";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { z } from "zod";
import Caption1 from "../UI/Text/Caption/Caption1";
import { useToast } from "../UI/Toast/use-toast";
import { DateTimePicker } from "../UI/shadcn/Time/date-time-picker";
import Button from "../UI/Button/Button";
import dayjs from "dayjs";
import ActionsCell from "../UI/ActionCell";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { Checkbox } from "../UI/shadcn/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../UI/shadcn/dialog";
import Body1 from "../UI/Text/Body/Body1";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import { UserAccount } from "@/src/types/types";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { toast as toastStackable } from "sonner";
import { numberToMoney } from "../../utils/financialHelpers";
import {
  ApproveCreditTransferMutation,
  CreditTransfersQuery,
  ListAllOperations,
  useApproveCreditTransferMutation,
  useCreditTransfersLazyQuery,
  useRejectCreditTransferMutation,
} from "../../generated/graphql";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import { getRefundStatuses } from "../../app/financeSlice";
import Subtitle2 from "../UI/Text/Subtitle/Subtitle2";

const options: { id: number; text: string }[] = [
  { id: 0, text: "Approve" },
  { id: 1, text: "Reject" },
];

const CreditTransferFilterSchema = z
  .object({
    startDate: z.date().optional(),
    endDate: z.date().optional(),
    statusId: z.number().optional(),
    regionId: z.number().optional(),
  })
  .refine(
    (data) => {
      return (
        data.endDate !== undefined &&
        data.startDate !== undefined &&
        data.endDate > data.startDate
      );
    },
    { message: "End date must occur after start date" }
  );

type CreditTransferFilterType = z.infer<typeof CreditTransferFilterSchema>;

const CreditTransfer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { refundStatuses: creditTransferStatuses } = useSelector(
    (state: RootState) => state.finance
  );
  const [selectedCreditTransfers, setSelectedCreditTransfers] = useState<
    number[]
  >([]);
  const [singleSelected, setSingleSelected] = useState<boolean>(false);
  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState<boolean>(false);
  const [summaryDialogData, setSummaryDialogData] = useState<
    ApproveCreditTransferMutation["approveCreditTransfer"] | undefined
  >(undefined);
  const [creditTransferData, setCreditTransferData] = useState<
    CreditTransfersQuery["creditTransfers"]
  >([]);
  const [creditTransferFilter, setCreditTransferFilter] =
    useState<CreditTransferFilterType>({
      startDate: dayjs().subtract(1, "week").toDate(),
      endDate: new Date(),
      statusId: 1,
      regionId: undefined,
    });

  const [creditTransfers, { data, loading, error }] =
    useCreditTransfersLazyQuery({
      fetchPolicy: "network-only",
    });

  const [approveCreditTransfer, { loading: processingCreditTransfers }] =
    useApproveCreditTransferMutation({
      refetchQueries: [ListAllOperations.Query.CreditTransfers],
    });

  const [rejectCreditTransfer, { loading: processingCreditTransferRejection }] =
    useRejectCreditTransferMutation({
      refetchQueries: [ListAllOperations.Query.CreditTransfers],
    });

  async function handleCreditTransfers() {
    await creditTransfers({
      variables: {
        creditTransferFilter: {
          ...creditTransferFilter,
          startDate:
            creditTransferFilter.startDate ??
            dayjs().subtract(1, "week").toDate(),
          endDate: creditTransferFilter.endDate ?? new Date(),
        },
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setCreditTransferData(data.creditTransfers);
      },
      onError: (error) => {
        dispatch(displayAlertError("Error occurred"));
      },
    });
  }

  async function handleApproveCreditTransfer() {
    await approveCreditTransfer({
      variables: {
        ids: selectedCreditTransfers,
      },
      onCompleted: (data) => {
        if (data) {
          if (data.approveCreditTransfer.success) {
            dispatch(displayAlertSuccess(data.approveCreditTransfer.message));
          } else {
            dispatch(displayAlertError(data.approveCreditTransfer.message));
          }
          setSelectedCreditTransfers([]);
          setOpenSummaryDialog(true);
          setSummaryDialogData(data.approveCreditTransfer);
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError("Error occurred"));
      },
    });
  }

  async function handleRejectCreditTransfer(id: number) {
    await rejectCreditTransfer({
      variables: {
        id: id,
      },
      onCompleted: (data) => {
        if (data) {
          if (data.rejectCreditTransfer.success) {
            dispatch(displayAlertSuccess(data.rejectCreditTransfer.message));
          } else {
            dispatch(displayAlertError(data.rejectCreditTransfer.message));
          }
        }
      },
      onError: (error) => {
        dispatch(displayAlertError("Error occurred"));
      },
    });
  }

  const handleSelectedOption = (
    row: CreditTransfersQuery["creditTransfers"][0],
    optionId: number
  ) => {
    if (optionId === 0) {
      setSelectedCreditTransfers([row.id]);
      setOpenApproveDialog(true);
      setSingleSelected(true);
    } else if (optionId === 1) {
      handleRejectCreditTransfer(row.id);
    }
  };

  useEffect(() => {
    handleCreditTransfers();
    dispatch(getRefundStatuses());
  }, []);

  const columnCreditTransfer: ColumnDef<
    CreditTransfersQuery["creditTransfers"][0]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setSelectedCreditTransfers(
              value
                ? creditTransferData
                    .filter((row) => row.statusId === 1)
                    .map((row) => row.id)
                : []
            );
            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = row.original.statusId === 1 && !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <>
            {row.original.statusId === 1 ? (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  if (value) {
                    setSelectedCreditTransfers((creditTransfers) => [
                      ...creditTransfers,
                      row.original.id,
                    ]);
                  } else {
                    setSelectedCreditTransfers((prevSelectedCreditTransfers) =>
                      prevSelectedCreditTransfers.filter(
                        (id) => id !== row.original.id
                      )
                    );
                  }
                  row.toggleSelected(!!value);
                }}
                disabled={row.original.statusId !== 1}
                aria-label="Select row"
              />
            ) : (
              <div></div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorFn: (row) => row.status.name || "N/A",
      id: "status",
      header: "Status",
    },
    {
      accessorFn: (row) => row.fromUser.firstName + " " + row.fromUser.lastName,
      id: "fromUser",
      header: "From Player",
    },
    {
      accessorFn: (row) => row.toUser.firstName + " " + row.toUser.lastName,
      id: "toUser",
      header: "To Player",
    },
    {
      accessorFn: (row) => `${numberToMoney(row.amount)}` || "N/A",
      id: "amount",
      header: "Amount",
    },
    {
      accessorFn: (row) =>
        `${row.createdByUser.firstName} ${row.createdByUser.lastName}` || "N/A",
      id: "createdByUser",
      header: "Created By",
    },
    {
      accessorFn: (row) =>
        dayjs(row.createdAt).format("YYYY-MM-DD HH:mm") || "N/A",
      id: "createdAt",
      header: "Created At",
    },
    {
      accessorFn: (row) =>
        `${
          row.approvedBy
            ? `${row.approvedByUser?.firstName} ${row.approvedByUser?.lastName}`
            : "N/A"
        }`,
      id: "approvedBy",
      header: "Approved By",
    },
    {
      accessorFn: (row) =>
        row.approvedAt
          ? dayjs(row.approvedAt).format("YYYY-MM-DD HH:mm")
          : "N/A",
      id: "approvedAt",
      header: "Approved At",
    },
    {
      cell: ({ row }) => {
        return (
          <>
            {options && row.original.statusId === 1 && (
              <ActionsCell
                row={row.original}
                options={options}
                handleSelectedOption={(event, optionId) =>
                  handleSelectedOption(row.original, optionId)
                }
              />
            )}
          </>
        );
      },
      header: "Actions",
    },
  ];

  if (loading) return <LoadingDialog open={loading} />;
  if (error) return <div>Something went wrong</div>;

  return (
    <main className="flex flex-col justify-between w-full gap-4">
      {processingCreditTransfers ||
        (processingCreditTransferRejection && <LoadingDialog open={true} />)}
      <Headline1Variable>Credit Transfer Approvals</Headline1Variable>
      <div className="flex flex-col gap-2 w-fit">
        <div className="grid items-end grid-cols-3 gap-4 ">
          <FormFieldSelect
            inputChange={(value) => {
              setCreditTransferFilter({
                ...creditTransferFilter,
                statusId: +value,
              });
            }}
            label={"Status"}
            placeholder=""
            value={
              creditTransferFilter.statusId
                ? creditTransferFilter.statusId.toString()
                : "0"
            }
          >
            {[{ id: 0, name: "All Statuses" }, ...creditTransferStatuses]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-row items-end w-full gap-4">
          <div className="flex flex-col gap-1">
            <Caption1 className="pl-3 font-medium">Start Date</Caption1>
            <DateTimePicker
              date={creditTransferFilter.startDate}
              setDate={(date) =>
                setCreditTransferFilter({
                  ...creditTransferFilter,
                  startDate: date,
                })
              }
              disabled={creditTransferFilter.statusId === 1}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Caption1 className="pl-3 font-medium">End Date</Caption1>
            <DateTimePicker
              date={creditTransferFilter.endDate}
              setDate={(date) =>
                setCreditTransferFilter({
                  ...creditTransferFilter,
                  endDate: date,
                })
              }
              disabled={creditTransferFilter.statusId === 1}
            />
          </div>
          <Button
            variant="primary"
            className="w-[280px] h-fit"
            onClick={() => handleCreditTransfers()}
          >
            Apply
          </Button>
        </div>
        <div className="flex flex-row items-end w-full gap-4">
          <Button
            className="w-[280px] h-fit"
            variant={
              selectedCreditTransfers.length > 0 && !singleSelected
                ? "primary"
                : "disabled"
            }
            disabled={!selectedCreditTransfers.length}
            onClick={() => setOpenApproveDialog(true)}
          >
            Approve All Selected
          </Button>
          <Button
            className="w-[280px] h-fit"
            variant={summaryDialogData === undefined ? "disabled" : "secondary"}
            disabled={summaryDialogData === undefined}
            onClick={() => setOpenSummaryDialog(true)}
          >
            Review recent approval
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {creditTransferData && creditTransferData.length > 0 && (
          <div className="flex flex-col gap-4">
            <DataTable
              data={creditTransferData}
              columns={columnCreditTransfer}
            />
            <Dialog
              open={openApproveDialog}
              onOpenChange={(value) => {
                if (singleSelected && value === false) {
                  setSelectedCreditTransfers([]);
                  setSingleSelected(false);
                }
                setOpenApproveDialog(value);
              }}
            >
              <DialogContent className="max-w-96">
                <DialogHeader>
                  <DialogTitle>CreditTransfer Confirmation</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col gap-4">
                    <Body1>
                      You&apos;re about to approve the credit transfers for{" "}
                      {selectedCreditTransfers.length} items. With a total value
                      of{" "}
                      {creditTransferData
                        .filter((creditTransfer) =>
                          selectedCreditTransfers.includes(creditTransfer.id)
                        )
                        .reduce(
                          (sum, creditTransfer, index) =>
                            (sum += Number(creditTransfer.amount)),
                          0
                        )
                        .toFixed(2)}
                    </Body1>
                  </div>
                </DialogDescription>
                <DialogClose>
                  <div className="flex flex-row gap-8 justify-evenly">
                    <Button
                      variant={"primary"}
                      onClick={() => handleApproveCreditTransfer()}
                    >
                      Approve
                    </Button>
                    <Button variant={"negative"}>Close</Button>
                  </div>
                </DialogClose>
              </DialogContent>
            </Dialog>
            {summaryDialogData && (
              <Dialog
                open={openSummaryDialog}
                onOpenChange={setOpenSummaryDialog}
              >
                <DialogContent className="max-w-[800px] max-h-[600px] overflow-scroll">
                  <div className="flex flex-col gap-4 ">
                    <Headline1Variable>
                      Credit Transfer Summary
                    </Headline1Variable>
                    <Body1>{summaryDialogData.message}</Body1>
                    {summaryDialogData.invalidCreditTransfers.length > 0 && (
                      <div className="flex flex-col gap-4">
                        <Subtitle1>Invalid CreditTransfers</Subtitle1>
                        <div className="flex flex-col gap-2">
                          {summaryDialogData.invalidCreditTransfers.map(
                            (invalidCreditTransfer) => {
                              return (
                                <Body1>
                                  Id: {invalidCreditTransfer.id} - Reason:{" "}
                                  {invalidCreditTransfer.reason}
                                </Body1>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <DialogClose>
                    <div className="flex flex-row gap-8 justify-evenly">
                      <Button variant={"negative"}>Close</Button>
                    </div>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default CreditTransfer;
