import {
  Division,
  DivisionByScheduleIdQuery,
  Game,
} from "../../../generated/graphql";
import { z } from "zod";
import { ordinal } from "./ScheduleNew";
import {
  DetailedTeamScoreRecord,
  GameForCalculatingStandings,
  PlacementToTeam,
  PlayoffGame,
  TeamPlayoffMatchupCategory,
  TeamPointsTotal,
  TeamScoreRecord,
} from "./ScheduleTypes";
import dayjs from "dayjs";

export function createPlacementToTeam(
  division: DivisionByScheduleIdQuery["division"]
): PlacementToTeam[] {
  return division.teams.map((team, index: number) => {
    return {
      placement: index,
      name: `${ordinal(index + 1)} Place`,
      teamId:
        Number(division.teams.find((t) => t.regSeasonStanding === index)?.id) ??
        null,
    };
  });
}

export function createPlayoffMatchupSelectOptions(
  division: DivisionByScheduleIdQuery["division"],
  numberOfWeek1PlayoffGames: number
): TeamPlayoffMatchupCategory[] {
  const teamsArrayPlayoffs1: TeamPlayoffMatchupCategory[] = division.teams.map(
    (team, index: number) => {
      return {
        id: `${index} Place`,
        value: index,
        type: 1,
        name: `${ordinal(index + 1)} Place`,
      };
    }
  );

  let teamsArrayPlayoffs2: TeamPlayoffMatchupCategory[] = [];

  for (let i = 0; i < numberOfWeek1PlayoffGames * 2; i++) {
    const gameNumber = Math.floor(i / 2) + 1;
    const status = i % 2 === 0 ? "Winner" : "Loser";
    teamsArrayPlayoffs2.push({
      id: `${status} Game ${i}`,
      value: i % 2 === 0 ? gameNumber : gameNumber * -1,
      type: 2,
      name: `${status} Game ${gameNumber}`,
    });
  }

  const teamsArrayPlayoffs3: TeamPlayoffMatchupCategory[] = division.teams?.map(
    (team) => {
      return {
        id: team.id.toString(),
        value: team.id,
        type: 3,
        name: `${team.name}`,
      };
    }
  );
  return [
    ...teamsArrayPlayoffs1,
    ...teamsArrayPlayoffs2,
    ...teamsArrayPlayoffs3,
  ];
}

export function generatePlayoffGames(
  teams: DivisionByScheduleIdQuery["division"]["teams"],
  startDate: string,
  numberOfWeeks: number,
  regularWeeks: number,
  exceptionDates: (string | null)[] | null | undefined, // Add this parameter
  gamesPerOccurrence: number
): PlayoffGame[] {
  const playoffGames: PlayoffGame[] = [];

  let numberOfTeams = teams.length;
  let weekNumber = 0;
  let iWeek = 0;
  let gamesInWeek = Math.floor(numberOfTeams / 2) * gamesPerOccurrence;
  let playoffGameCounter = 1;
  while (weekNumber < numberOfWeeks) {
    const week = dayjs(startDate).add(iWeek * 7, "days");
    const weekFormat = week.format("dddd YYYY-MM-DD");

    // Check if week is an exclusion date
    const isExclusionDate = exceptionDates?.find(
      (date) => dayjs(date).format("dddd YYYY-MM-DD") === weekFormat
    );

    if (isExclusionDate) {
      iWeek++;
      continue;
    }

    weekNumber++;
    const isFirstWeek = weekNumber === 1;
    const gameType = isFirstWeek ? 1 : 2;
    for (let game = 1; game <= gamesInWeek; game++) {
      if (isFirstWeek) {
        // First week: Highest vs Lowest seed
        const homeTeamValue = game - 1;
        const awayTeamValue = numberOfTeams - game;

        playoffGames.push({
          id: undefined,
          startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
          venueId: 0,
          homeTeamId: undefined,
          awayTeamId: undefined,
          gameTypeId: 2, // Playoff Schedule
          isVisible: 0, //Not visible
          week: weekNumber + regularWeeks,
          gameStatusId: 1,
          countHomeScore: true,
          countAwayScore: true,
          homeTeamType: gameType,
          homeTeamValue,
          awayTeamType: gameType,
          awayTeamValue,
          playoffGameNumber: playoffGameCounter,
          isChampGame: 0,
          isDeleted: false,
          isDoubleHeader: false,
        });
      } else {
        // Second week onwards: Winners vs Winners, Losers vs Losers
        const isWinnersGame = game <= Math.ceil(gamesInWeek / 2);
        const gameIndex = isWinnersGame
          ? game
          : game - Math.ceil(gamesInWeek / 2);

        playoffGames.push({
          id: undefined,
          startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
          venueId: 0,
          homeTeamId: undefined,
          awayTeamId: undefined,
          gameTypeId: 2, // Playoff Schedule
          isVisible: 0, //Not visible
          week: weekNumber + regularWeeks,
          gameStatusId: 1,
          countHomeScore: true,
          countAwayScore: true,
          homeTeamType: gameType,
          homeTeamValue: isWinnersGame ? gameIndex : -gameIndex,
          awayTeamType: gameType,
          awayTeamValue: isWinnersGame ? gameIndex + 1 : -(gameIndex + 1),
          playoffGameNumber: playoffGameCounter,
          isChampGame: 0,
          isDeleted: false,
          isDoubleHeader: false,
        });
      }
      playoffGameCounter++;
    }

    // Handle odd number of teams
    if (numberOfTeams % 2 !== 0) {
      for (let i = 1; i <= gamesPerOccurrence; i++) {
        if (isFirstWeek) {
          playoffGames.push({
            id: undefined,
            startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
            venueId: 0,
            homeTeamId: undefined,
            awayTeamId: undefined,
            gameTypeId: 2, // Playoff Schedule
            isVisible: 0,
            week: weekNumber + regularWeeks,
            gameStatusId: 1,
            countHomeScore: true,
            countAwayScore: true,
            homeTeamType: 3,
            homeTeamValue: +teams[teams.length - i].id,
            awayTeamType: undefined,
            awayTeamValue: undefined,
            playoffGameNumber: playoffGameCounter,
            isDeleted: false,
            isChampGame: 0,
            isDoubleHeader: false,
          });
        } else {
          playoffGames.push({
            id: undefined,
            startDateTimeLocal: week.hour(18).format("YYYY-MM-DD HH:mm:ss"),
            venueId: 0,
            homeTeamId: undefined,
            awayTeamId: undefined,
            gameTypeId: 2, // Playoff Schedule
            isVisible: 0,
            week: weekNumber + regularWeeks,
            gameStatusId: 1,
            countHomeScore: true,
            countAwayScore: true,
            homeTeamType: 3,
            homeTeamValue: +teams[teams.length - i].id,
            awayTeamType: undefined,
            awayTeamValue: undefined,
            playoffGameNumber: playoffGameCounter,
            isDeleted: false,
            isChampGame: 0,
            isDoubleHeader: false,
          });
        }
        playoffGameCounter++;
      }
    }

    // Reduce the number of teams for the next week
    iWeek++;
  }

  return playoffGames;
}

// Creates the date for the week header based on the division start date, week number, and any exclusion dates
export function dateForWeek(
  weekNumber: number,
  division?: DivisionByScheduleIdQuery["division"]
) {
  if (!division) {
    return "";
  }
  let totalWeeksPast = 0;
  for (let i = 0; i < weekNumber; i++) {
    const week = dayjs(division.startDate).add(i * 7, "days");
    const weekFormat = week.format("dddd YYYY-MM-DD");

    // Check if week is an exclusion date
    const isExclusionDate = division.exceptionDates?.find(
      (date) => dayjs(date).format("dddd YYYY-MM-DD") === weekFormat
    );

    if (isExclusionDate) {
      weekNumber++;
    }
    totalWeeksPast = i;
  }

  let week = dayjs(division.startDate).add(totalWeeksPast * 7, "days");
  let weekFormat = week.format("dddd YYYY-MM-DD");

  return weekFormat;
}
