import { SEND_OPENAI_MESSAGE } from "../../../../graphql/queries/openAI";
import { ChatList } from "./chat-list";
import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";

export interface AiMessage {
  role: string;
  content: string;
}

export function JaimsChat() {
  const [messagesState, setMessages] = React.useState<AiMessage[]>([
    {
      role: "system",
      content: "You are a helpful assistant.",
    },
  ]);

  const [sendOpenAiMessage, { loading }] = useMutation(SEND_OPENAI_MESSAGE);

  const sendMessage = async (newMessage: AiMessage) => {
    setMessages([
      ...messagesState,
      newMessage,
      { role: "assistant", content: "Loading..." },
    ]);
  };

  useEffect(() => {
    if (messagesState[messagesState.length - 1].content === "Loading...") {
      sendOpenAiMessage({
        variables: {
          messages: [...messagesState.slice(0, -1)],
        },
        onCompleted: async (data) => {
          setMessages([
            ...messagesState.slice(0, -1),
            { role: data.sendMessage.role, content: data.sendMessage.content },
          ]);
        },
      });
    }
  }, [messagesState]);

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <ChatList
        messages={messagesState}
        sendMessage={sendMessage}
        loadingResponse={loading}
      />
    </div>
  );
}
