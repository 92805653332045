import React, { FC, FormEvent, useEffect, useState } from "react";
import { ChatListBase, MessageType } from "./chatListBase";
import dayjs from "dayjs";

interface TeamChatProps {
  teamPlayers: {
    id: string;
    name: string;
    role?: string;
    teamName?: string;
  }[];
  channelName?: string;
  chatId: number | null;
  messages: MessageType[];
  disabled?: boolean;
}

const TeamChat: FC<TeamChatProps> = ({
  teamPlayers,
  channelName,
  chatId,
  messages,
  disabled = false,
}) => {
  // const { data: session } = useSession();
  // const [messageText, setMessageText] = useState<MessageType[] | []>(messages);

  // const [createChatMessage] = useCreateChatMessageMutation();

  // const { channel, connectionError, channelError } = useChannel(
  //   channelName,
  //   (message) => {
  //     const history = messageText.slice(-199);
  //     const role = message.data.userId === session?.user.id ? "me" : "other";
  //     const playerName = teamPlayers.find(
  //       (player) => player.id === message.data.userId
  //     );
  //     setMessageText((prev) => [
  //       ...prev,
  //       {
  //         role: role,
  //         content: message.data.data,
  //         name: playerName?.name || "",
  //         createdAt: dayjs().format("MMM D, YYYY h:mm A"),
  //         teamRole: playerName?.role,
  //         teamName: playerName?.teamName,
  //       },
  //     ]);
  //   }
  // );

  // const sendMessage = async (newMessage: MessageType) => {
  //   if (!chatId) return;
  //   createChatMessage({
  //     variables: {
  //       message: newMessage.content,
  //       chatId: chatId,
  //       userId: session?.user.id || "",
  //     },
  //     onCompleted: (data) => {
  //       channel.publish({
  //         name: "chat-message",
  //         data: { data: newMessage.content, userId: session?.user.id },
  //       });
  //     },
  //   });
  // };
  return (
    <ChatListBase
      messages={messages}
      loadingResponse={false}
      disabled={disabled}
      // error={!!connectionError || !!channelError}
    />
  );
};

export default TeamChat;
