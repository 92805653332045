import { FileImage, Paperclip, SendHorizontal } from "lucide-react";
import React, { useRef, useState } from "react";
import { buttonVariants } from "../button";
import { cn } from "../../../../lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Textarea } from "../textarea";
import { MessageType } from "./chatListBase";
import TextArea from "../../../UI/FormField/TextArea/TextAreaWithCounter";

interface ChatBottombarBaseProps {
  sendMessage: (newMessage: MessageType) => void;
  loadingResponse: boolean;
  disabled?: boolean;
}

export const BottombarIcons = [{ icon: FileImage }, { icon: Paperclip }];

export default function ChatBottombarBase({
  sendMessage,
  loadingResponse,
  disabled = false,
}: ChatBottombarBaseProps) {
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    if (disabled) return;
    if (message.trim() && !loadingResponse) {
      const newMessage: MessageType = {
        role: "me",
        content: message.trim(),
        name: "You",
      };
      sendMessage(newMessage);
      setMessage("");

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
    if (loadingResponse) {
      console.log("loadingResponse");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + "\n");
    }
  };

  return (
    <div className="flex items-center justify-between w-full gap-2 p-2 bg-white rounded-sm shadow-2xl">
      <AnimatePresence initial={false}>
        <motion.div
          key="input"
          className="relative w-full"
          layout
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{
            opacity: { duration: 0.05 },
            layout: {
              type: "spring",
              bounce: 0.15,
            },
          }}
        >
          <TextArea
            autoComplete="off"
            value={message}
            ref={inputRef}
            onKeyDown={handleKeyPress}
            onChange={handleInputChange}
            name="message"
            placeholder=""
            disabled={disabled}
            id="message"
            title=""
            maxInput={255}
          ></TextArea>
        </motion.div>
        <a
          className={`${cn(buttonVariants({ size: "icon" }), "h-9 w-9")} ${
            disabled
              ? "bg-gray-200 cursor-not-allowed"
              : "bg-primary-80 rounded-sm m-auto text-dark hover:bg-primary-90"
          }`}
          onClick={handleSend}
        >
          <SendHorizontal
            size={20}
            className="text-muted-foreground"
          />
        </a>
      </AnimatePresence>
    </div>
  );
}
