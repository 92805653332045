import { cn } from "../../../../lib/utils";
import React, { useRef } from "react";
import { Avatar, AvatarImage } from "../avatar";
import ChatBottombar from "./chat-bottombar";
import { AnimatePresence, motion } from "framer-motion";
import { AiMessage } from "./jaimsChat";
import { Bot, UserRound } from "lucide-react";

interface ChatListProps {
  messages?: AiMessage[];
  sendMessage: (newMessage: AiMessage) => void;
  loadingResponse: boolean;
}

export function ChatList({
  messages,
  sendMessage,
  loadingResponse,
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="flex flex-col justify-between w-full h-full max-h-screen overflow-x-hidden overflow-y-auto">
      <div
        ref={messagesContainerRef}
        className="flex flex-col w-full overflow-x-hidden overflow-y-auto"
      >
        <AnimatePresence>
          {messages
            ?.filter((message) => message.role !== "system")
            .map((message, index) => (
              <motion.div
                key={index}
                layout
                initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
                transition={{
                  opacity: { duration: 0.1 },
                  layout: {
                    type: "spring",
                    bounce: 0.3,
                    duration: messages.indexOf(message) * 0.05 + 0.2,
                  },
                }}
                style={{
                  originX: 0.5,
                  originY: 0.5,
                }}
                className={cn(
                  "flex flex-col gap-2 p-4 whitespace-pre-wrap",
                  message.role === "user" ? "items-end" : "items-start"
                )}
              >
                <div className="flex items-center gap-3">
                  {message.role === "assistant" && (
                    <div className="flex items-center justify-center w-8 h-8 p-1 rounded-full bg-secondary-70">
                      <Bot className="w-6 h-6 text-black " />
                    </div>
                  )}
                  <span
                    className={cn(
                      "max-w-full p-3 rounded-md",
                      message.role === "user"
                        ? "bg-primary-95"
                        : "bg-secondary-90"
                    )}
                  >
                    {message.content}
                  </span>
                  {message.role === "user" && (
                    <div className="flex items-center justify-center w-8 h-8 p-1 rounded-full bg-primary-90">
                      <UserRound className="w-6 h-6 text-black" />
                    </div>
                  )}
                </div>
              </motion.div>
            ))}
        </AnimatePresence>
      </div>
      <ChatBottombar
        sendMessage={sendMessage}
        loadingResponse={loadingResponse}
      />
    </div>
  );
}
