import React, { useEffect, useRef, useState } from "react";
import FormField from "../FormField";
import {
  GetUsersQuery,
  useGetUsersLazyQuery,
} from "../../../../generated/graphql";

type FormFieldPlayerSeachProps = {
  onUserSelect(user: GetUsersQuery["getUsers"]["users"][0]): void;
  ignoreUserIds?: string[];
};

const FormFieldPlayerSeach = ({
  onUserSelect,
  ignoreUserIds = [],
}: FormFieldPlayerSeachProps) => {
  const inputRef = useRef<HTMLDivElement>(null); // Reference for the input field
  const autocompleteRef = useRef<HTMLDivElement>(null); // Reference for the autocomplete list
  const [userFilter, setUserFilter] = useState<string | null>(null);
  const [usersListAutoComplete, setUsersListAutoComplete] = useState<
    GetUsersQuery["getUsers"]["users"]
  >([]);
  const [getUserAutoCompleteList] = useGetUsersLazyQuery({
    variables: {
      userFilter: userFilter,
    },
    onCompleted: (data) => {
      setUsersListAutoComplete(
        data.getUsers.users.filter(
          (user) => ignoreUserIds.indexOf(user.id) === -1
        )
      );
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (userFilter && userFilter?.length > 0)
      getUserAutoCompleteList({
        variables: {
          userFilter: userFilter,
          page: null,
          pageSize: null,
        },
      });
  }, [userFilter]);
  return (
    <div className="flex flex-row items-end h-full gap-4">
      <div
        className="w-96 h-[90%]"
        ref={inputRef}
      >
        <FormField
          initialValue={userFilter || ""}
          inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > 2) setUserFilter(e.target.value);
            else setUserFilter(null);
          }}
          onKeyDown={(event) => {
            if (
              usersListAutoComplete.length > 0 &&
              userFilter &&
              event.key === "Enter"
            ) {
              setUsersListAutoComplete([]);
            }
          }}
          name="search"
          placeholder="Player Search"
          width="full"
          className="bg-gray-100"
        ></FormField>
        {usersListAutoComplete.length > 0 && userFilter ? (
          <div
            className="absolute z-10 overflow-hidden w-96 max-h-[40vh] overflow-y-auto border rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
            ref={autocompleteRef}
          >
            <div className="max-w-md bg-white">
              <div className="flow-root">
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                  {usersListAutoComplete.map((user) => (
                    <li
                      className="px-4 py-2 rounded-md cursor-pointer hover:bg-primary-95"
                      onClick={() => {
                        setUsersListAutoComplete([]);
                        setUserFilter(null);
                        onUserSelect(user);
                      }}
                    >
                      <div className="flex items-center space-x-4 rounded-md">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {user.email} - {user.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FormFieldPlayerSeach;
