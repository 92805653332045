import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import axios from "axios";
import { getJwtCookie, removeJwtCookie, setJwtCookie } from "../app/authSlice";
import { baseApiUrl } from "../utils/baseUrl";

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_ENV === "production"
      ? "https://admin.jamsports.com/api/graphql"
      : process.env.REACT_APP_ENV === "staging"
      ? "https://admin.jamitall.com/api/graphql"
      : "http://localhost:3001/graphql",
  credentials: "same-origin",
});

// Error handling link
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        console.log("GraphQL Error:", error);

        // Check for UNAUTHENTICATED errors
        if (error.extensions?.code === "UNAUTHENTICATED") {
          const rememberMe = localStorage.getItem("rememberMe");
          return fromPromise(
            axios
              .post(
                `${baseApiUrl}/auth/refresh`,
                {},
                {
                  withCredentials: true,
                  params: { rememberMe: rememberMe },
                }
              )
              .then((response) => {
                const newAccessToken = response.data.token;

                if (newAccessToken) {
                  // Save the new token
                  setJwtCookie(newAccessToken);

                  // Update the operation's headers
                  operation.setContext(({ headers = {} }) => ({
                    headers: {
                      ...headers,
                      authorization: `Bearer ${newAccessToken}`,
                    },
                  }));
                } else {
                  console.log("GraphQL Error 1:", error);
                  removeJwtCookie();
                  window.location.href = "/login?sessionExpired=true";
                }
              })
              .catch((error) => {
                console.log("GraphQL Error 2:", error);
                removeJwtCookie();
                window.location.href = "/login?sessionExpired=true";
              })
          ).flatMap(() => forward(operation)); // Retry the original operation
        }
      }
    }

    if (networkError) {
      process.env.REACT_APP_ENV === "development" &&
        console.log(`[Network Error]: ${networkError}`);
      return;
    }
  }
);

// Auth link to add the access token to each request
const authLink = new ApolloLink((operation, forward) => {
  const token = getJwtCookie();

  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }));

  return forward(operation);
});

// Apollo Client setup
export const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
