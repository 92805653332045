import React from "react";
import { JaimsChat } from "../UI/shadcn/chat/jaimsChat";

const JAIms: React.FC = () => {
  return (
    <main className="flex flex-col items-center justify-center">
      <div className="flex flex-col z-10 w-full max-w-5xl h-[650px] max-h-[650px] text-sm border border-black rounded-lg">
        <JaimsChat />
      </div>
    </main>
  );
};

export default JAIms;
