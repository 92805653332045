import { createContext, useContext, useState } from "react";
import { GameslotsBySessionIdQuery } from "../generated/graphql";

type ScheduleGameslotContextType = {
  gameslots: GameslotsBySessionIdQuery["gameslotsBySessionId"];
  setGameslots: React.Dispatch<
    React.SetStateAction<GameslotsBySessionIdQuery["gameslotsBySessionId"]>
  >;
};

const ScheduleGameslotContextDefault: ScheduleGameslotContextType = {
  gameslots: [],
  setGameslots: () => {},
};

const ScheduleGameslotContext = createContext<ScheduleGameslotContextType>(
  ScheduleGameslotContextDefault
);

export const ScheduleGameslotContextProvider = ({ children }: any) => {
  const [gameslots, setGameslots] = useState<
    GameslotsBySessionIdQuery["gameslotsBySessionId"]
  >([]);

  const value = {
    gameslots,
    setGameslots,
  };

  return (
    <ScheduleGameslotContext.Provider value={value}>
      {children}
    </ScheduleGameslotContext.Provider>
  );
};

export const useScheduleGameslotContext = () =>
  useContext(ScheduleGameslotContext);
