import dayjs from "dayjs";
import { GameslotsBySessionIdQuery } from "../generated/graphql";

export const checkGameToGameslotAllocation = (
  gameslots: GameslotsBySessionIdQuery["gameslotsBySessionId"],
  game: {
    startDateTimeLocal: string;
    duration: number;
    venueId: number;
  }
): boolean => {
  // Check if the game fits in one of the gameslots
  const gameStart = dayjs(game.startDateTimeLocal);
  const gameEnd = dayjs(game.startDateTimeLocal).add(game.duration, "minutes");

  const gameslotVenueIds = gameslots.filter(
    (gs) => gs.venueId === game.venueId
  );

  const gameSlot = gameslotVenueIds.find((gs) => {
    const gsStart = dayjs(gs.startDateTimeLocal);
    const gsEnd = dayjs(gs.startDateTimeLocal).add(gs.duration, "minutes");

    return (
      gameStart.isSameOrAfter(gsStart) &&
      gameEnd.isSameOrBefore(gsEnd) &&
      gs.venueId === game.venueId
    );
  });

  return !!gameSlot;
};
