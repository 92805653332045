import { gql } from "@apollo/client";

const GET_COUNTRIES = gql`
  query Countries {
    countries {
      id
      name
    }
  }
`;

const GET_STATES = gql`
  query States {
    states {
      id
      name
      countryId
      stateCode
    }
  }
`;

gql`
  query Country($countryId: Int!) {
    country(id: $countryId) {
      id
      name
    }
  }
`;

gql`
  query State($stateId: Int!) {
    state(id: $stateId) {
      id
      name
      countryId
      stateCode
    }
  }
`;

const GET_STATES_BY_COUNTRY_ID = gql`
  query StatesByCountry($countryId: Int) {
    statesByCountryId(countryId: $countryId) {
      id
      name
      countryId
      stateCode
    }
  }
`;

export { GET_COUNTRIES, GET_STATES, GET_STATES_BY_COUNTRY_ID };
