import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AllShifts,
  AllContractorShiftsReport,
  Shift,
  RequestedShift,
  ShiftNotes,
} from "./components/PTS/Administrator";
import {
  OpenShifts,
  MyShifts,
  AppliedShifts,
} from "./components/PTS/Ambassdor";
import {
  Vendors,
  ContractManagement,
  CreateVenue,
  Venues,
  ShoppingCart,
  ShoppingCarts,
  LegalDocumentManagement,
} from "./components/Admin";
import SportFormats from "./components/Admin/SportFormats";
import CreateSportFormat from "./components/Admin/CreateSportFormat";
import CreateLeague from "./components/Admin/CreateLeague";
import Leagues from "./components/Admin/Leagues";
import Seasons from "./components/Admin/Seasons";
import CreateSeason from "./components/Admin/CreateSeason";
import CreateSession from "./components/Admin/CreateSession";
import Sessions from "./components/Admin/Sessions";
import Divisions from "./components/Admin/Divisions";
import CreateDivision from "./components/Admin/CreateDivision";

import { Home, Error, Register } from "./pages";

import ProtectedRoute from "./pages/ProtectedRoutes";
import CreateIndyTeam from "./components/Admin/CreateIndyTeam";
import PermissionDenied from "./pages/PermissionDenied";
import Base from "./components/Home/Base";
import VenueHome from "./components/Admin/HomePages/OperationVenueHome";
import LeagueHome from "./components/Admin/HomePages/OperationLeagueHome";
import ScheduleHome from "./components/Admin/HomePages/OperationScheduleHome";
import PlayerReportHome from "./components/Admin/HomePages/PlayerReportHome";
import EventHome from "./components/Admin/HomePages/OperationEventHome";
import PlayerSearchHome from "./components/Admin/HomePages/PlayerSearchHome";
import StaffingStaffHome from "./components/Admin/HomePages/StaffingStaffHome";
import StaffingShiftsHome from "./components/Admin/HomePages/StaffingShiftsHome";
import StaffingReportHome from "./components/Admin/HomePages/StaffingReportHome";
import MarketingContentManagementHome from "./components/Admin/HomePages/MarketingContentManagementHome";
import MarketingMailingListHome from "./components/Admin/HomePages/MarketingMailingListHome";
import FinanceTransactionsHome from "./components/Admin/HomePages/FinanceTransactionsHome";
import FinanceCreditsHome from "./components/Admin/HomePages/FinanceCreditsHome";
import FinancePromoCodesHome from "./components/Admin/HomePages/FinancePromoCodesHome";
import Content from "./components/Marketing/Content";
import Rules from "./components/Admin/Rules";
import CreateVendor from "./components/Admin/CreateVendor";
import CreateSubVenue from "./components/Admin/CreateSubVenue";
import CreateShoppingCart from "./components/Admin/CreateShoppingCart";
import TestPage from "./components/Home/TestPage";
import Players from "./components/Players/Players";
import Player from "./components/Players/Player";
import Registrations from "./components/Admin/Registrations";
import EmailTool from "./components/Marketing/Email/EmailTool";
import Wayfinder from "./components/Admin/LeagueWayfinder";
import Permissions from "./components/Players/Permissions";
import AuthorizedRoutes from "./pages/AuthorizedRoutes";
import { EPermission } from "./utils/permissions";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "./app/authSlice";
import EmailToolDivisions from "./components/Marketing/Email/EmailToolDivisions";
import JAIms from "./components/Admin/JAIms";
import EmailTemplateManager from "./components/Marketing/Email/EmailTemplateManager";
import GameReport from "./components/Admin/GameReport";
import RefundApproval from "./components/Admin/RefundApprovalReport";
import PayrollBatches from "./components/PTS/Administrator/PayrollBatches";
import CreatePayrollBatches from "./components/PTS/Administrator/CreatePayrollBatches";
import PayrollBatch from "./components/PTS/Administrator/PayrollBatch";
import ShiftsForReview from "./components/PTS/Administrator/ShiftsForReview";
import ShiftsForPayroll from "./components/PTS/Administrator/ShiftsForPayroll";
import EmailToolMarketing from "./components/Marketing/Email/EmailToolMarketing";
import EmailToolMarketingFilter from "./components/Marketing/Email/EmailToolMarketingFilter";
import CreateContract from "./components/Admin/Contract/CreateContractNew";
import ScheduleTest from "./components/Admin/Schedule/ScheduleTest";
import ScheduleNew from "./components/Admin/Schedule/ScheduleNew";
import ReseedDivisions from "./components/Admin/ReseedDivisions";
import EmailMarketingReport from "./components/Marketing/EmailMarketingReport";
import EmailToolMarketingSend from "./components/Marketing/Email/EmailToolMarketingSend";
import WaitList from "./components/Players/WaitList";
import RulesReport from "./components/Admin/RulesReport";
import ImageManager from "./components/Admin/ImageManager";
import GameScoreReport from "./components/Admin/GameScoreReport";
import StaffTypeManagement from "./components/PTS/Administrator/StaffTypeManagement/StaffTypeManagement";
import StaffTypeManagementReport from "./components/PTS/Administrator/StaffTypeManagement/StaffTypeManagementReport";
import { StaffTypeContextProvider } from "./context/StaffTypeContext";
import ThirdPartyContractorReport from "./components/PTS/Administrator/ThirdPartyContractor/ThirdPartyContractorReport";
import ThirdPartyContractor from "./components/PTS/Administrator/ThirdPartyContractor/ThirdPartyContractor";
import CapacityGroupOverview from "./components/Admin/CapacityGroup/CapacityGroupOverview";
import CapacityGroup from "./components/Admin/CapacityGroup/CapacityGroup";
import VenueOverview from "./components/Admin/VenueOverview/VenueOverview";
import BugReport from "./components/Dev/BugReport";
import FeatureReport from "./components/Dev/FeatureReport";
import { PageTitleContextProvider } from "./context/PageTitleContext";
import Weather from "./components/Admin/Weather/Weather";
import LogoutUser from "./pages/LogoutUser";
import EditContract from "./components/Admin/Contract/EditContract";
import SessionWorkflow from "./components/Admin/Session/SessionWorkflow/SessionWorkflow";
import EmailToolSessions from "./components/Marketing/Email/EmailToolSessions";
import Team from "./components/Players/TeamHq";
import GameSummary from "./components/Admin/GameSummary";
import TournamentReport from "./components/Admin/Tournament/TournamentReport";
import TournamentManagement from "./components/Admin/Tournament/TournamentManagement";
import CreditTransfer from "./components/Admin/CreditTransferReport";

function App() {
  return (
    <BrowserRouter>
      <PageTitleContextProvider>
        <Routes>
          <Route
            path={`/login`}
            element={<Register />}
          />
          <Route
            path={`/`}
            element={<Home />}
          >
            {/* HOME PAGES */}
            {/* Operations */}
            <Route
              path={`/ops/venue`}
              element={
                <ProtectedRoute>
                  <VenueHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/schedule`}
              element={
                <ProtectedRoute>
                  <ScheduleHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/event`}
              element={
                <ProtectedRoute>
                  <EventHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/game-scores`}
              element={
                <ProtectedRoute>
                  <GameScoreReport />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/game-report`}
              element={
                <ProtectedRoute>
                  <GameReport />
                </ProtectedRoute>
              }
            />
            {/* PLAYERS */}
            <Route
              path={`/players/reports`}
              element={
                <ProtectedRoute>
                  <PlayerReportHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/search`}
              element={
                <ProtectedRoute>
                  <PlayerSearchHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/users`}
              element={
                <ProtectedRoute>
                  <Players />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/user/:id`}
              element={
                <ProtectedRoute>
                  <Player />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/team/:id`}
              element={
                <ProtectedRoute>
                  <Team />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/permissions`}
              element={
                <ProtectedRoute>
                  <Permissions />
                </ProtectedRoute>
              }
            />
            {/* STAFFING */}
            <Route
              path={`/staffing/staff`}
              element={
                <ProtectedRoute>
                  <StaffingStaffHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/shifts`}
              element={
                <ProtectedRoute>
                  <StaffingShiftsHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/reports`}
              element={
                <ProtectedRoute>
                  <StaffingReportHome />
                </ProtectedRoute>
              }
            />
            {/* MARKETING */}
            <Route
              path={`/marketing/content-management`}
              element={
                <ProtectedRoute>
                  <MarketingContentManagementHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/division/:id?`}
              element={
                <ProtectedRoute>
                  <EmailToolDivisions />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/session/:id?`}
              element={
                <ProtectedRoute>
                  <EmailToolSessions />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/marketing/:id?`}
              element={
                <ProtectedRoute>
                  <EmailToolMarketing />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/marketing/report`}
              element={
                <ProtectedRoute>
                  <EmailMarketingReport />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/marketing/filter/:id?`}
              element={
                <ProtectedRoute>
                  <EmailToolMarketingFilter />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/marketing/send/:id`}
              element={
                <ProtectedRoute>
                  <EmailToolMarketingSend />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool/email-template-manager`}
              element={
                <ProtectedRoute>
                  <EmailTemplateManager />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/email-tool`}
              element={
                <ProtectedRoute>
                  <EmailTool />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/content`}
              element={
                <ProtectedRoute>
                  <Content />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/mailing-list`}
              element={
                <ProtectedRoute>
                  <MarketingMailingListHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/jAIms`}
              element={
                <ProtectedRoute>
                  <JAIms />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/marketing/image-manager`}
              element={
                <ProtectedRoute>
                  <ImageManager />
                </ProtectedRoute>
              }
            />
            {/* FINANCE */}
            <Route
              path={`/finance/transactions`}
              element={
                <ProtectedRoute>
                  <FinanceTransactionsHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/credits`}
              element={
                <ProtectedRoute>
                  <FinanceCreditsHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/promo-codes`}
              element={
                <ProtectedRoute>
                  <FinancePromoCodesHome />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/refund-approvals`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["REFUND_APPROVAL"]}
                >
                  <RefundApproval />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/credit-transfer`}
              element={
                <AuthorizedRoutes
                  requiredPermission={EPermission["REFUND_APPROVAL"]}
                >
                  <CreditTransfer />
                </AuthorizedRoutes>
              }
            />
            <Route
              path={`/finance/payrollBatches`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["FINANCE_PAYROLL"]}
                  >
                    <PayrollBatches />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/createPayrollBatch`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["FINANCE_PAYROLL"]}
                  >
                    <CreatePayrollBatches />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/createPayrollBatch/:id`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["FINANCE_PAYROLL"]}
                  >
                    <CreatePayrollBatches />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/shiftsForReview`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["CREATE_SHIFT"]}
                  >
                    <ShiftsForReview />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/shiftsForPayroll`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["FINANCE_PAYROLL"]}
                  >
                    <ShiftsForPayroll />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/finance/payrollBatch/:id`}
              element={
                <ProtectedRoute>
                  <PayrollBatch />
                </ProtectedRoute>
              }
            />
            {/* OPS */}
            <Route
              path={`/ops/contracts`}
              element={
                <ProtectedRoute>
                  <ContractManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/weather`}
              element={
                <ProtectedRoute>
                  <Weather />
                </ProtectedRoute>
              }
            />
            {/* create initial state as prop here */}
            <Route
              path={`/ops/contract/new`}
              element={
                <ProtectedRoute>
                  <CreateContract />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/contract/:id`}
              element={
                <ProtectedRoute>
                  <EditContract />
                </ProtectedRoute>
              }
            />
            {/* <Route
            path={`/create-venue`}
            element={
              <ProtectedRoute>
                <CreateVenue />
              </ProtectedRoute>
            }
          /> */}
            <Route
              path={`/ops/venue-overview`}
              element={
                <ProtectedRoute>
                  <VenueOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/venues`}
              element={
                <ProtectedRoute>
                  <Venues />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/venue-management`}
              element={
                <ProtectedRoute>
                  <CreateVenue />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/venue-management/:id`}
              element={
                <ProtectedRoute>
                  <CreateVenue />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/sub-venue-management/:parentId`}
              element={
                <ProtectedRoute>
                  <CreateSubVenue />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/sub-venue-management/:parentId/:id`}
              element={
                <ProtectedRoute>
                  <CreateSubVenue />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/vendors`}
              element={
                <ProtectedRoute>
                  <Vendors />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/vendor`}
              element={
                <ProtectedRoute>
                  <CreateVendor />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/vendor/:id`}
              element={
                <ProtectedRoute>
                  <CreateVendor />
                </ProtectedRoute>
              }
            />
            {/* DEV */}
            <Route
              path={`/dev/bug-report`}
              element={
                <ProtectedRoute>
                  <BugReport />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/dev/feature-report`}
              element={
                <ProtectedRoute>
                  <FeatureReport />
                </ProtectedRoute>
              }
            />
            {/* PTS */}
            <Route
              path={`/staffing/shift/new`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["CREATE_SHIFT"]}
                  >
                    <Shift requiredPermission={EPermission["CREATE_SHIFT"]} />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/shift/:id`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["EDIT_SHIFT"]}
                  >
                    <Shift requiredPermission={EPermission["EDIT_SHIFT"]} />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/all-staff-shifts`}
              element={
                <ProtectedRoute>
                  <AllShifts />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/all-contractor-shifts`}
              element={
                <ProtectedRoute>
                  <AllContractorShiftsReport />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/requested-shifts`}
              element={
                <ProtectedRoute>
                  <RequestedShift />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/staff-type-management`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                  >
                    <StaffTypeManagementReport />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/staff-type-management/:id?`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                  >
                    <StaffTypeManagement />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/third-party-contractor-management`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                  >
                    <ThirdPartyContractorReport />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/third-party-contractor-management/:id?`}
              element={
                <ProtectedRoute>
                  <AuthorizedRoutes
                    requiredPermission={EPermission["MANAGE_SHIFT_STAFF"]}
                  >
                    <ThirdPartyContractor />
                  </AuthorizedRoutes>
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/shift-notes/:id`}
              element={
                <ProtectedRoute>
                  <ShiftNotes />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/open-shifts`}
              element={
                <ProtectedRoute>
                  <OpenShifts />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/applied-shifts`}
              element={
                <ProtectedRoute>
                  <AppliedShifts />
                </ProtectedRoute>
              }
            />
            {/* <Route
            path={`/staffing/shifts-finance`}
            element={
              <ProtectedRoute>
                <ShiftsFinance />
              </ProtectedRoute>
            }
          /> */}
            <Route
              path={`/ops/sport-format/new`}
              element={
                <ProtectedRoute>
                  <CreateSportFormat />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/sport-format`}
              element={
                <ProtectedRoute>
                  <SportFormats />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/sport-format/:id`}
              element={
                <ProtectedRoute>
                  <CreateSportFormat />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/leagues`}
              element={
                <ProtectedRoute>
                  <Leagues />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/league`}
              element={
                <ProtectedRoute>
                  <CreateLeague />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/league/:id`}
              element={
                <ProtectedRoute>
                  <CreateLeague />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/seasons`}
              element={
                <ProtectedRoute>
                  <Seasons />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/rule/:id?`}
              element={
                <ProtectedRoute>
                  <Rules />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/rules`}
              element={
                <ProtectedRoute>
                  <RulesReport />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/season/:id`}
              element={
                <ProtectedRoute>
                  <CreateSeason />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/season`}
              element={
                <ProtectedRoute>
                  <CreateSeason />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/session`}
              element={
                <ProtectedRoute>
                  <CreateSession />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/session-hq/:id`}
              element={
                <ProtectedRoute>
                  <SessionWorkflow />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/session/:id`}
              element={
                <ProtectedRoute>
                  <CreateSession />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/sessions`}
              element={
                <ProtectedRoute>
                  <Sessions />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/wayfinder`}
              element={
                <ProtectedRoute>
                  <Wayfinder />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/free-agent-teams`}
              element={
                <ProtectedRoute>
                  <CreateIndyTeam />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/divisions`}
              element={
                <ProtectedRoute>
                  <Divisions />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/division/:id`}
              element={
                <ProtectedRoute>
                  <CreateDivision />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/division`}
              element={
                <ProtectedRoute>
                  <CreateDivision />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/division/reseed/:id`}
              element={
                <ProtectedRoute>
                  <ReseedDivisions />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/division/:id/schedule`}
              element={
                <ProtectedRoute>
                  <ScheduleNew />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/tournaments`}
              element={
                <ProtectedRoute>
                  <TournamentReport />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/tournament/:id`}
              element={
                <ProtectedRoute>
                  <TournamentManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/tournament/new`}
              element={
                <ProtectedRoute>
                  <TournamentManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/division/test`}
              element={
                <ProtectedRoute>
                  <ScheduleTest />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/capacity-group/:id`}
              element={
                <ProtectedRoute>
                  <CapacityGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/ops/capacity-group-overview`}
              element={
                <ProtectedRoute>
                  <CapacityGroupOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/shopping-cart/:id`}
              element={
                <ProtectedRoute>
                  <ShoppingCart />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/waitlist`}
              element={
                <ProtectedRoute>
                  <WaitList />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/shopping-carts`}
              element={
                <ProtectedRoute>
                  <ShoppingCarts />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/Testing`}
              element={
                <ProtectedRoute>
                  <TestPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/staffing/my-shifts`}
              element={
                <ProtectedRoute>
                  <MyShifts />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/dev/legal-document-management`}
              element={
                <ProtectedRoute>
                  <LegalDocumentManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/create-shopping-cart`}
              element={
                <ProtectedRoute>
                  <CreateShoppingCart />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/registrations`}
              element={
                <ProtectedRoute>
                  <Registrations />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/players/game-summary`}
              element={
                <ProtectedRoute>
                  <GameSummary />
                </ProtectedRoute>
              }
            />
            <Route
              index
              element={
                <ProtectedRoute>
                  <Base />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/testPage"
            element={<TestPage />}
          />
          <Route
            path="/permissionDenied"
            element={<PermissionDenied />}
          />
          <Route
            path="/logout"
            element={<LogoutUser />}
          />
          <Route
            path="*"
            element={<Error />}
          />
        </Routes>
      </PageTitleContextProvider>
    </BrowserRouter>
  );
}

export default App;
