"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../../../lib/utils";
import { Button } from "../button";
import { Calendar } from "../calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { TimePickerDemo } from "./time-picker-demo";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

dayjs.extend(utc);
dayjs.extend(timezone);

export function DateTimePicker({
  date,
  setDate,
  disabled,
  label,
  className,
  error,
}: {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  error?: boolean;
}) {
  return (
    <div className={cn(`flex flex-col w-full text-black`)}>
      <div className={`px-3 text-xs font-medium`}>{label}</div>
      <Popover>
        <PopoverTrigger
          asChild
          disabled={disabled}
          className={cn(className)}
        >
          <Button
            variant={"outline"}
            className={cn(
              "w-[280px] justify-start text-left font-normal rounded-xl",
              !date && "text-muted-foreground",
              error &&
                "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
            )}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            {date ? format(date, "PPP HH:mm:ss") : <span>Pick a date</span>}
            {error && !disabled && (
              <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            initialFocus
            defaultMonth={date}
          />
          <div className="p-3 border-t border-border">
            <TimePickerDemo
              setDate={setDate}
              date={date}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
