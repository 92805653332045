import { cn } from "../../../../lib/utils";
import React, { useRef } from "react";
import ChatBottombar from "./chatBottomBarBase";
import { AnimatePresence, motion } from "framer-motion";
import { Bot, UserRound } from "lucide-react";
import Alert from "../../Alerts/Alert";

export interface MessageType {
  role: "me" | "other" | "system";
  content: string;
  name: string;
  createdAt?: string;
  teamRole?: string;
  teamName?: string;
}

interface ChatListBaseProps {
  messages?: MessageType[];
  sendMessage?: (newMessage: MessageType) => void;
  loadingResponse: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

export function ChatListBase({
  messages,
  sendMessage,
  loadingResponse,
  disabled = false,
  error = false,
  className,
}: ChatListBaseProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const errorMessage =
    "Our chat servers are taking a quick timeout! You can still view the chat history, but we can't send new messages right now. Please try again later.";
  const disabledMessage =
    "Only team reps (captains and alternates) can send messages in the game chat, but all players can view the chat history.";

  return (
    <div
      className={cn(
        "flex flex-col justify-between w-full h-[75vh] overflow-x-hidden overflow-y-auto",
        className
      )}
    >
      <div
        ref={messagesContainerRef}
        className="flex flex-col w-full overflow-x-hidden overflow-y-auto"
      >
        <AnimatePresence>
          {messages
            ?.filter((message) => message.role !== "system")
            .map((message, index) => (
              <motion.div
                key={index}
                layout
                initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
                transition={{
                  opacity: { duration: 0.1 },
                  layout: {
                    type: "spring",
                    bounce: 0.3,
                    duration: messages.indexOf(message) * 0.05 + 0.2,
                  },
                }}
                style={{
                  originX: 0.5,
                  originY: 0.5,
                }}
                className={cn(
                  "flex flex-col gap-2 p-2 whitespace-pre-wrap",
                  message.role === "me" ? "items-end" : "items-start"
                )}
              >
                <span
                  className={cn(
                    "p-3 rounded-md shadow-md max-w-[90%] lg:max-w-[70%] text-start break-words",
                    message.role === "me" ? "bg-primary-95" : "bg-white"
                  )}
                >
                  <div className="flex justify-between gap-4 text-sm font-semibold text-start">
                    {message.role === "me" ? "You" : message.name}
                    <div className="text-xs text-light">
                      {message.createdAt}
                    </div>
                  </div>
                  {message.teamRole && (
                    <div className="text-xs text-light">
                      {message.teamName} ({message.teamRole})
                    </div>
                  )}
                  {message.content}
                </span>
              </motion.div>
            ))}
        </AnimatePresence>
      </div>
      <div>
        {error ||
          (disabled && (
            <Alert
              size="small"
              persist={true}
              variant="error"
              title={undefined}
              content={error ? errorMessage : disabledMessage}
            />
          ))}
        {sendMessage && (
          <ChatBottombar
            sendMessage={sendMessage}
            loadingResponse={loadingResponse}
            disabled={disabled || error}
          />
        )}
      </div>
    </div>
  );
}
