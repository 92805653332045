import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { PaginationType } from "../../../types/types";
import BaseTable from "../../UI/Table/Table";
import Button from "../../UI/Button/Button";
import ActionsCell from "../../UI/ActionCell";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import {
  TournamentsWithFilterPaginatedQuery,
  useTournamentsWithFilterPaginatedLazyQuery,
} from "../../../generated/graphql";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getSports } from "../../../app/venueMasterSlice";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../UI/shadcn/dropdown";
import { MoreVerticalIcon } from "lucide-react";
import { DataTable } from "../../UI/Table/DataTable";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../UI/shadcn/select";
import { Pagination } from "@mui/material";
import dayjs from "dayjs";
import { baseURL } from "../../../utils/baseUrl";

const TournamentReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/

  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    pagination: PaginationType;
    regionId: number;
    sportId: number;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: pageSize,
    },
    regionId: 0,
    sportId: 0,
  });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [getTournaments, { loading, data }] =
    useTournamentsWithFilterPaginatedLazyQuery({
      onCompleted: (data) => {
        const pageCount = Math.ceil(
          data.tournamentsWithFilterPaginated.count / pageSize
        );
        setFilter((prevState) => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            pageCount: pageCount,
          },
        }));
      },
    });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (filter) {
      getTournaments({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
          regionId: filter.regionId ?? 0,
          sportId: filter.sportId ?? 0,
        },
      });
    }
  }, [filter.pagination.page, filter.regionId, filter.sportId]);

  // Get any redux data needed for filters here
  useEffect(() => {
    dispatch(getSports(""));
  }, [dispatch]);

  /*** UTILITY FUNCTIONS ***/

  const COLUMNS: ColumnDef<
    TournamentsWithFilterPaginatedQuery["tournamentsWithFilterPaginated"]["tournaments"][0]
  >[] = [
    {
      accessorKey: "id",
      header: "Id",
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50 hover:text-info-70"
            href={`${baseURL}/tournaments/${row.original.id}`}
          >
            {row.original.name}
          </a>
        );
      },
    },
    {
      accessorKey: "regOpenDateTime",
      header: "Registration Open Date",
      accessorFn: (row) => {
        return dayjs(row.regOpenDateTime).format("YYYY-MM-DD H:mm:ss");
      },
    },
    {
      accessorKey: "regCloseDateTime",
      header: "Registration Close Date",
      accessorFn: (row) => {
        return dayjs(row.regCloseDateTime).format("YYYY-MM-DD H:mm:ss");
      },
    },
    {
      accessorKey: "startDateTimeLocal",
      header: "Start Time",
      accessorFn: (row) => {
        return dayjs(row.startDateTimeLocal).format("YYYY-MM-DD H:mm:ss");
      },
    },
    {
      accessorKey: "endDateTimeLocal",
      header: "End Time",
      accessorFn: (row) => {
        return dayjs(row.endDateTimeLocal).format("YYYY-MM-DD H:mm:ss");
      },
    },
    {
      accessorKey: "sportFormat",
      header: "Sport Format",
      accessorFn: (row) => {
        return row.sportFormat.name;
      },
    },
    {
      header: "Action",
      cell: ({ row }) => {
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <MoreVerticalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="flex flex-col gap-1">
                <DropdownMenuItem>
                  <a href={`/ops/tournament/${row.original.id}`}>View/Edit</a>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
  ];
  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loading} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Tournaments</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            href={"/ops/tournament/new"}
          >
            <AddIcon />
            New Tournaments
          </Button>
        </div>
      </div>
      {/* Filtering section */}
      <div className="flex flex-row gap-4">
        <div className="max-w-60 min-w-60">
          {sports.length > 0 && (
            <FormFieldSelect
              name="sportId"
              id="sportId"
              value={filter.sportId ? filter.sportId.toString() : "0"}
              inputChange={(value) => {
                setFilter((prevState) => ({
                  ...prevState,
                  sportId: +value,
                }));
              }}
              label="Sport"
              placeholder="Select Sport"
              className="h-[46px] m-0"
            >
              {[{ id: 0, name: "All" }, ...sports]}
            </FormFieldSelect>
          )}
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={filter.regionId ? filter.regionId.toString() : "0"}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="h-[46px] m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
      </div>
      {/* Table section */}
      {data && (
        <DataTable
          columns={COLUMNS}
          data={data.tournamentsWithFilterPaginated.tournaments}
        />
      )}
      {filter.pagination.pageCount ? (
        <>
          <div className="my-auto text-xs text-disabled">
            Page {filter.pagination.page + 1} of{" "}
            {Math.ceil(
              filter.pagination.pageCount / filter.pagination.pageSize
            )}
          </div>
          <div className="flex flex-row">
            <Pagination
              page={filter.pagination.page + 1}
              count={Math.ceil(
                filter.pagination.pageCount / filter.pagination.pageSize
              )}
              onChange={(event: any, value: number) => {
                setFilter((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    page: value - 1,
                  },
                }));
              }}
            />
            <Select
              value={`${filter.pagination.pageSize.toString()}`}
              onValueChange={(value) => {
                setFilter((prevState) => ({
                  ...prevState,
                  pagination: {
                    ...prevState.pagination,
                    page: Math.floor(
                      (prevState.pagination.page *
                        prevState.pagination.pageSize) /
                        Number(value)
                    ),
                    pageSize: Number(value),
                  },
                }));
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue
                  placeholder={filter.pagination.pageSize.toString()}
                />
              </SelectTrigger>
              <SelectContent side="top">
                {[5, 10, 25, 50, 100].map((pageSize) => (
                  <SelectItem
                    key={pageSize}
                    value={`${pageSize}`}
                    className="hover:bg-primary-95"
                  >
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </>
      ) : null}
    </main>
  );
};

export default TournamentReport;
