import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../UI/shadcn/table";
import { matchupValueToName } from "../Admin/Schedule/GameSchedule";
import dayjs from "dayjs";
import { ScrollArea } from "../UI/shadcn/scroll-area";

const ScheduleBackup = ({ rows }: { rows: any }) => {
  return (
    <ScrollArea className="max-h-[400px]">
      <Table className="min-w-[650px] my-2">
        <TableHeader>
          <TableRow>
            <TableHead>GameID</TableHead>
            <TableHead className="text-left">Start Time Date</TableHead>
            <TableHead className="text-left">Venue</TableHead>
            <TableHead className="text-left">Home Team</TableHead>
            <TableHead className="text-left">Away Team</TableHead>
            <TableHead className="text-left">Player Visibility</TableHead>
            <TableHead className="text-left">Game Type</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows && rows.length > 0 ? (
            rows.map((row: any) => (
              <TableRow key={row.name}>
                <TableCell>{row.id}</TableCell>
                <TableCell className="text-left">
                  {dayjs(row.startDateTimeLocal).format("MMMM DD, YYYY h:mm a")}
                </TableCell>
                <TableCell className="text-left">{row.venue.name}</TableCell>
                <TableCell className="text-left">
                  {row.gameType.id === 2
                    ? row.homeTeam === null
                      ? matchupValueToName(
                          row.gamePlayoffMatchup.homeTeamValue,
                          row.gamePlayoffMatchup.homeTeamType
                        )
                      : row.homeTeam.name
                    : row.homeTeam.name}
                </TableCell>
                <TableCell className="text-left">
                  {row.gameType.id === 2
                    ? row.awayTeam === null
                      ? matchupValueToName(
                          row.gamePlayoffMatchup.awayTeamValue,
                          row.gamePlayoffMatchup.awayTeamType
                        )
                      : row.awayTeam.name
                    : row.awayTeam.name}
                </TableCell>
                <TableCell className="text-left">
                  {row.isVisible ? "Visible" : "Hidden"}
                </TableCell>
                <TableCell className="text-left">
                  {row.gameType?.gameTypeName}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={7}
                className="text-center"
              >
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ScrollArea>
  );
};

export default ScheduleBackup;
