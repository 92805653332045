import { useState, useEffect, useRef } from "react";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import Headline1Variable from "../Text/Headline/Headline1Variable";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "YOUR_API_KEY";

export function extractAddressFields(place: google.maps.places.PlaceResult) {
  const components = place.address_components || [];

  const getAddressComponent = (type: string) => {
    const component = components.find((c) => c.types.includes(type));
    return component ? component.long_name : "";
  };

  const streetNumber = getAddressComponent("street_number");
  const route = getAddressComponent("route");
  const city =
    getAddressComponent("locality") ||
    getAddressComponent("administrative_area_level_2");
  const province = getAddressComponent("administrative_area_level_1");
  const postalCode = getAddressComponent("postal_code");
  const country = getAddressComponent("country");

  // Combine street number and route to form a full street address
  const streetAddress = [streetNumber, route].filter(Boolean).join(" ");

  return {
    streetAddress,
    city,
    province,
    postalCode,
    country,
  };
}

const AutoCompleteAddressFormField = ({
  setSelectedPlace,
}: {
  setSelectedPlace: React.Dispatch<
    React.SetStateAction<google.maps.places.PlaceResult | null>
  >;
}) => {
  return (
    <div className="w-full h-fit">
      <APIProvider
        apiKey={API_KEY}
        solutionChannel="JAM_Location_selector"
      >
        <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />
      </APIProvider>
    </div>
  );
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const PlaceAutocomplete = ({ onPlaceSelect }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      fields: [
        "geometry",
        "name",
        "formatted_address",
        "address_components",
        "place_id",
      ],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <input
        ref={inputRef}
        data-1p-ignore
        className={
          "w-full font-normal rounded-xl px-3 py-2 h-[40px] border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80"
        }
      />
    </div>
  );
};

export default AutoCompleteAddressFormField;
