import {
  FileImage,
  Mic,
  Paperclip,
  PlusCircle,
  SendHorizontal,
  Smile,
  ThumbsUp,
} from "lucide-react";
import React, { useRef, useState } from "react";
import { buttonVariants } from "../button";
import { cn } from "../../../../lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Textarea } from "../textarea";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { AiMessage } from "./jaimsChat";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../app/store";
import { displayAlertError } from "../../../../app/globalSlice";

interface ChatBottombarProps {
  sendMessage: (newMessage: AiMessage) => void;
  loadingResponse: boolean;
}

export const BottombarIcons = [{ icon: FileImage }, { icon: Paperclip }];

export default function ChatBottombar({
  sendMessage,
  loadingResponse,
}: ChatBottombarProps) {
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleSend = () => {
    if (message.trim() && !loadingResponse) {
      const newMessage: AiMessage = {
        role: "user",
        content: message.trim(),
      };
      sendMessage(newMessage);
      setMessage("");

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
    if (loadingResponse) {
      dispatch(
        displayAlertError(
          "Please wait until your last message receives a response."
        )
      );
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + "\n");
    }
  };

  return (
    <div className="flex items-center justify-between w-full gap-2 p-2">
      <AnimatePresence initial={false}>
        <motion.div
          key="input"
          className="relative w-full"
          layout
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{
            opacity: { duration: 0.05 },
            layout: {
              type: "spring",
              bounce: 0.15,
            },
          }}
        >
          <Textarea
            autoComplete="off"
            value={message}
            ref={inputRef}
            onKeyDown={handleKeyPress}
            onChange={handleInputChange}
            name="message"
            placeholder="Write your message to JAIms"
            className="flex items-center w-full h-20 border rounded-lg resize-y min-h-20 max-h-40 bg-background"
          ></Textarea>
        </motion.div>
        <a
          href="#"
          className={cn(
            buttonVariants({ variant: "ghost", size: "icon" }),
            "h-9 w-9",
            "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white shrink-0"
          )}
          onClick={handleSend}
        >
          <SendHorizontal
            size={20}
            className="text-muted-foreground"
          />
        </a>
      </AnimatePresence>
    </div>
  );
}
