import CapacityGroup from "../components/Admin/CapacityGroup/CapacityGroup";
import { AllContractorShiftContextProvider } from "./AllContractorShiftContext";
import { CapacityGroupContextProvider } from "./CapacityGroupContext";
import { EmailToolMarketingContextProvider } from "./EmailToolMarketingContext";
import { ImageManagerContextProvider } from "./ImageMangerContext";
import { PageTitleContextProvider } from "./PageTitleContext";
import { SessionWorkflowContextProvider } from "./SessionWorkflowContext";
import { StaffTypeContextProvider } from "./StaffTypeContext";
import { VenueOverviewContextProvider } from "./VenueOverviewContext";
import { WeatherContextProvider } from "./WeatherContext";
import { ScheduleGameslotContextProvider } from "./ScheduleGameslotContext";

export const WrapperProvider = ({ children }: any) => {
  return (
    <SessionWorkflowContextProvider>
      <WeatherContextProvider>
        <VenueOverviewContextProvider>
          <CapacityGroupContextProvider>
            <AllContractorShiftContextProvider>
              <EmailToolMarketingContextProvider>
                <StaffTypeContextProvider>
                  <ImageManagerContextProvider>
                    <ScheduleGameslotContextProvider>
                      {children}
                    </ScheduleGameslotContextProvider>
                  </ImageManagerContextProvider>
                </StaffTypeContextProvider>
              </EmailToolMarketingContextProvider>
            </AllContractorShiftContextProvider>
          </CapacityGroupContextProvider>
        </VenueOverviewContextProvider>
      </WeatherContextProvider>
    </SessionWorkflowContextProvider>
  );
};
