import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import NormalLogo from "../../assets/logos/JAMsports_logo_smaller.png";
import SmallLogo from "../../assets/logos/JAMsports_logo_smallest.png";

import React, { useEffect, useRef, useState } from "react";
import {
  getJwtCookie,
  getUserDetails,
  logout,
  removeJwtCookie,
} from "../../app/authSlice";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { getRegions, handleSelectedRegions } from "../../app/venueMasterSlice";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { Users } from "@/src/types/types";
import FormField from "../UI/FormField/FormField";
import {
  GetUsersQuery,
  RegionsQuery,
  useGetUsersLazyQuery,
} from "../../../src/generated/graphql";
import WeatherNavBar from "../Admin/Weather/WeatherNavBar";
import CheckBox from "../UI/Checkbox/Checkbox";
import { Checkbox } from "../UI/shadcn/checkbox";
import { rest, set } from "lodash";
import { Label } from "../UI/shadcn/label";
import Body1 from "../UI/Text/Body/Body1";
import Cookies from "js-cookie";
import FormFieldPlayerSeach from "../UI/FormField/FormFieldPlayerSearch/FormFieldPlayerSearch";

const SELECTED_REGIONS_COOKIE_NAME = "selectedRegions";

const settings = [
  // { id: 1, text: 'Profile', to: `/profile` },
  { id: 3, text: "Logout", to: `/logout` },
];

const Navbar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { regions } = useSelector((state: RootState) => state.venueMaster);
  const { user, token }: any = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElRegion, setAnchorElRegion] = useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const [selectedRegions, setSelectedRegions] = React.useState<
    RegionsQuery["regions"]
  >([]);
  const [regionFilter, setRegionFilter] = useState<
    (RegionsQuery["regions"][0] & { selected: boolean })[]
  >([]);
  const [userFilter, setUserFilter] = useState<string | null>(null);
  const [usersListAutoComplete, setUsersListAutoComplete] = useState<
    GetUsersQuery["getUsers"]["users"]
  >([]);

  const open = Boolean(anchorElRegion);

  const inputRef = useRef<HTMLDivElement>(null); // Reference for the input field
  const autocompleteRef = useRef<HTMLDivElement>(null); // Reference for the autocomplete list

  const [getUserAutoCompleteList] = useGetUsersLazyQuery({
    variables: {
      userFilter: userFilter,
    },
    onCompleted: (data) => {
      setUsersListAutoComplete(data.getUsers.users);
    },
    fetchPolicy: "network-only",
  });

  const handleClickRegion = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRegion(event.currentTarget);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleUserMenuOptions = (
    event: React.MouseEvent<HTMLElement>,
    idx: number
  ) => {
    if (idx === 3) {
      handleLogout();
    }
  };

  const pathname: string = window.location.pathname;

  const handleLogout = () => {
    dispatch(logout());
  };

  const onClickChangeAll = (state: boolean) => {
    setRegionFilter((prevState) => {
      return prevState.map((region) => {
        return { ...region, selected: state };
      });
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node)
      ) {
        setUsersListAutoComplete([]); // Clear the autocomplete list on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getRegions(""));
    if (!user) {
      const token = getJwtCookie();
      if (token == null) {
        // navigate(`/login`);
        return;
      }
      dispatch(getUserDetails(token));
    }
  }, [dispatch]);

  useEffect(() => {
    if (regions.length > 0) {
      const regionsCopy = [...regions];
      let selectionRegions = regionsCopy.map((region) => ({
        ...region,
        selected: true,
      }));
      const savedSelectedRegions = Cookies.get(SELECTED_REGIONS_COOKIE_NAME);
      if (savedSelectedRegions) {
        const savedSelectedRegionsArray: number[] =
          JSON.parse(savedSelectedRegions);
        selectionRegions = regionsCopy.map((region) => ({
          ...region,
          selected: savedSelectedRegionsArray.some(
            (selectedRegion) => selectedRegion === region.id
          ),
        }));
      }
      setRegionFilter(selectionRegions);
    }
  }, [regions]);

  useEffect(() => {
    if (regionFilter.length === 0) {
      return;
    }
    const selectedRegions = regionFilter
      .filter((region) => region.selected)
      .map((region) => {
        const { selected, ...regionCopy } = region;
        return regionCopy;
      });
    Cookies.set(
      SELECTED_REGIONS_COOKIE_NAME,
      JSON.stringify(selectedRegions.map((region) => region.id)),
      {
        expires: 365,
      }
    );
    dispatch(handleSelectedRegions(selectedRegions));
  }, [regionFilter]);

  useEffect(() => {
    getUserAutoCompleteList({
      variables: {
        userFilter: userFilter,
        page: null,
        pageSize: null,
      },
    });
  }, [userFilter]);

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }: any) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor:
          process.env.REACT_APP_ENV === "production"
            ? "var(--secondary-10)"
            : "var(--staging-nav)",

        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar className="flex justify-between">
        {/* Part 1: JAM ICON */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            paddingBottom: "5px",
            paddingTop: "5px",
          }}
        >
          <img
            src={SmallLogo}
            srcSet={`${SmallLogo} 1x, ${NormalLogo} 2x`}
            sizes="100px"
            alt="JAMsports Logo"
            style={{ width: "100px", height: "auto" }}
          />
        </IconButton>

        <div className="flex gap-2">
          <FormFieldPlayerSeach
            onUserSelect={(player) => navigate(`/players/user/${player.id}`)}
          />
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClickRegion}
            style={{ color: "white", paddingRight: "20px" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Regions
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            anchorEl={anchorElRegion}
            open={open}
            onClose={handleCloseRegion}
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
          >
            <>
              <MenuItem
                style={{ padding: "0px 10px", justifyContent: "center" }}
                disableRipple
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickChangeAll(true)}
                >
                  SELECT ALL
                </Button>
              </MenuItem>
              <MenuItem
                style={{ padding: "0px 10px", justifyContent: "center" }}
                disableRipple
              >
                <Button
                  size="small"
                  variant="text"
                  style={{ color: "gray" }}
                  onClick={() => onClickChangeAll(false)}
                >
                  DESELECT ALL
                </Button>
              </MenuItem>
              {regionFilter &&
                regionFilter.map((region, index: number) => (
                  <MenuItem
                    key={region.id}
                    disableRipple
                    className="flex w-full gap-2"
                  >
                    <Checkbox
                      className="hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
                      checked={region.selected}
                      onCheckedChange={(value: boolean) => {
                        setRegionFilter((prevState) => {
                          return prevState.map((prevRegion, idx) => {
                            if (idx === index) {
                              return { ...prevRegion, selected: value };
                            }
                            return prevRegion;
                          });
                        });
                      }}
                      id={`region-${region.id}`}
                      aria-label="region"
                    />
                    <Label
                      htmlFor={`region-${region.id}`}
                      className="w-full hover:cursor-pointer"
                    >
                      <Body1 className=" whitespace-nowrap">
                        {region.name}
                      </Body1>
                    </Label>
                  </MenuItem>
                ))}
            </>
          </StyledMenu>
          <WeatherNavBar />

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, color: "white" }}
            >
              <PersonOutlineRoundedIcon color="inherit" />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.id}
                  onClick={(event) => {
                    handleUserMenuOptions(event, setting.id);
                  }}
                >
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
