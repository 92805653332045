import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { getPrivacySettings, updateUserById } from "../../app/cxSlice";
import { getAllPermissions } from "../../app/authSlice";
import Card from "../UI/Card/Card";
import FormField from "../UI/FormField/FormField";
import CheckBox from "../UI/Checkbox/Checkbox";
import Button from "../UI/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../types/types";
import { LoadingMaterialUI } from "../UI";
import Disclaimer from "../UI/Alerts/Disclaimer";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import moment from "moment";
import TabGroup from "../UI/Tab/TabGroup/TabGroup";
import { Column } from "react-table";
import dayjs from "dayjs";
import BaseTable from "../UI/Table/Table";
import { DayOfWeek } from "../../app/venueMasterSlice";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import {
  CircularProgress,
  IconButton,
  Button as MuiButton,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../UI/shadcn/dialog";
import utc from "dayjs/plugin/utc";
import { emailButton } from "../Marketing/Email/EmailButton";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Notification from "./Notification";
import {
  CreateCreditTransferInput,
  CreditsByUserIdQuery,
  GetAllShoppingCartsByUserIdQuery,
  GetUsersQuery,
  ListAllOperations,
  Region,
  RegistrationByUserIdQuery,
  RegistrationInvitationsByUserIdQuery,
  ShirtSizeAllQuery,
  TeamPlayerByUserIdQuery,
  TournamentRegistrationsByUserIdQuery,
  useCreateCreditTransferMutation,
  useCreateFreeLoaderRegInvitationMutation,
  useCreditsByUserIdLazyQuery,
  useGetAllShoppingCartsByUserIdLazyQuery,
  useGetDiscountRefundReasonsQuery,
  useGetEmailLogsByUserLazyQuery,
  useGetEmailLogsMailgunByUserLazyQuery,
  UserByIdGetQuery,
  useRegistrationByUserIdLazyQuery,
  useRegistrationInvitationsByUserIdLazyQuery,
  useRemoveRegistrationInvitationMutation,
  useShirtSizeAllQuery,
  useTeamPlayerByUserIdLazyQuery,
  useTournamentRegistrationsByUserIdLazyQuery,
  useUpdateUserMutation,
  useUserByIdGetQuery,
} from "../../generated/graphql";
import Body1 from "../UI/Text/Body/Body1";
import Body2 from "../UI/Text/Body/Body2";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { numberToMoney } from "../../utils/financialHelpers";
import WaitlistSessionsDialog from "../UI/Dialog/WaitlistSessionsDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import FormFieldViewOnly from "../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import { baseAdminURL, baseApiUrl, baseURL } from "../../utils/baseUrl";
import AvailableSessionsDialog, {
  SelectedSession,
} from "../UI/Dialog/AvailableSessionsDialog";
import Caption1 from "../UI/Text/Caption/Caption1";
import {
  EPermission,
  filterPermittedRegions,
  isPermissionGranted,
} from "../../utils/permissions";
import { LockOutlined } from "@mui/icons-material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import { MoreVerticalIcon } from "lucide-react";
import FormFieldPlayerSeach from "../UI/FormField/FormFieldPlayerSearch/FormFieldPlayerSearch";
import { cn } from "../../lib/utils";

dayjs.extend(utc);

const initialUser: UserByIdGetQuery["userByIdGet"] = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  genderIdentity: {
    id: 0,
    name: "",
  },
  phoneNumber: "",
  isStaff: false,
  isAdmin: false,
  isSuperAdmin: false,
  marketingEmail: false,
  permission: [],
  privacyGender: 2,
  privacyPhoneNumber: 2,
  regionId: 0,
  shirtSizeId: 0,
  scoreReminderEmail: false,
  gameReminderEmail: false,
  registrationOpeningEmail: false,
  sportSpecificEmail: false,
  sportEmailIds: [],
};

type TabGroupType =
  | "Info"
  | "Cart"
  | "Registration"
  | "Team"
  | "Permission"
  | "Email Logs"
  | "Notification"
  | "Credit";

interface CellParam {
  row: {
    original: RegistrationInvitationsByUserIdQuery["registrationInvitationsByUserId"][0];
  };
  rows: {
    original: RegistrationInvitationsByUserIdQuery["registrationInvitationsByUserId"][0];
  }[];
}

const Player: React.FC = () => {
  const pathsBreadcrumbs = [
    { name: "Player Search", url: "/players/users" },
    { name: "Player Info", url: "" },
  ];

  const pageSize = 10;
  const pageSizeDropdown = 20;
  const paginationDefault: Pagination = {
    pageCount: 0,
    pageSize: pageSize,
    page: 0,
  };
  const paginationDropdownDefault: Pagination = {
    pageCount: 0,
    pageSize: pageSizeDropdown,
    page: 0,
  };

  const params = useParams();
  const isEditPlayer = params.id;
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, privacySettings }: any = useSelector(
    (state: RootState) => state.cx
  );
  const { selectedRegions, regions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { permissions, user: admin }: any = useSelector(
    (state: RootState) => state.auth
  );

  const [playerUserId, setPlayerUserId] = useState<string>("");
  const [user, setUser] =
    useState<UserByIdGetQuery["userByIdGet"]>(initialUser);
  const [tabGroupValue, setTabGroupValue] = useState<TabGroupType>("Info");
  const [shoppingCarts, setShoppingCarts] = useState<
    GetAllShoppingCartsByUserIdQuery["getAllShoppingCartsByUserId"]
  >([]);
  const [registrations, setRegistrations] = useState<
    (
      | RegistrationByUserIdQuery["registrationByUserId"][0]
      | TournamentRegistrationsByUserIdQuery["tournamentRegistrationsByUserId"][0]
    )[]
  >([]);
  const [registrationInvitations, setRegistrationInvitations] = useState<
    RegistrationInvitationsByUserIdQuery["registrationInvitationsByUserId"][0][]
  >([]);
  const [teams, setTeams] = useState<
    TeamPlayerByUserIdQuery["teamPlayerByUserId"]
  >([]);

  const [credits, setCredits] = useState<
    CreditsByUserIdQuery["creditsByUserId"]
  >([]);
  const [shirtSizes, setShirtSizes] = useState<
    ShirtSizeAllQuery["shirtSizeAll"]
  >([]);
  const [paginationMailgun, setPaginationMailgun] =
    useState<Pagination>(paginationDefault);
  const [paginationEmail, setPaginationEmail] = useState<Pagination>(
    paginationDropdownDefault
  );
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [creditTransfer, setCreditTransfer] = useState<
    | {
        toUserName: string;
        toUserEmail: string;
        fromUserName: string;
        fromUserEmail: string;
        toUserId: string;
        fromUserId: string;
        amount: number;
        originalCreditId: number;
      }
    | undefined
  >(undefined);
  const [openModal, setOpenModal] = useState(false);

  const [openWaitlistDialog, setOpenWaitlistDialog] = useState<boolean>(false);
  const [openAvailableSessionsDialog, setOpenAvailableSessionsDialog] =
    useState<boolean>(false);
  const [confirmDirectReg, setConfirmDirectReg] = useState<{
    open: boolean;
    session?: SelectedSession;
    discountType: number;
    regInviteUrl?: string;
  }>({
    open: false,
    discountType: 19, // Default to 19 for free loader
  });
  const [openRegInviteModal, setOpenRegInviteModal] = useState<{
    open: boolean;
    regInvite?: RegistrationInvitationsByUserIdQuery["registrationInvitationsByUserId"][0];
    type: "remove" | "regInviteURL";
  }>({
    open: false,
    type: "remove",
  });

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (isEditPlayer) {
      if (isEditPlayer !== playerUserId) {
        setPlayerUserId(isEditPlayer);
        setTabGroupValue("Info");
      }
    }
  }, [isEditPlayer]);

  const [UpdateUser, { data, loading, error }] = useUpdateUserMutation();
  const [createFreeLoaderRegInvite, { loading: loadingFreeLoaderReg }] =
    useCreateFreeLoaderRegInvitationMutation();
  const [removeRegInvite, { loading: loadingRemoveRegInvite }] =
    useRemoveRegistrationInvitationMutation();
  const [CreateCreditTransfer] = useCreateCreditTransferMutation();

  const { data: refundReasons, loading: refundReasonsLoading } =
    useGetDiscountRefundReasonsQuery();

  const [
    getAllCartsByUserId,
    { data: cartByUserId, loading: cartLoading, error: cartError },
  ] = useGetAllShoppingCartsByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [
    getRegistrationsByUserId,
    {
      data: registrationsByUserId,
      loading: registrationLoading,
      error: registrationError,
    },
  ] = useRegistrationByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [
    getTournamentRegistrationsByUserId,
    {
      data: tournamentRegistrationsByUserId,
      loading: tournamentRegistrationLoading,
      error: tournamentRegistrationError,
    },
  ] = useTournamentRegistrationsByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [
    getRegInvitationsByUserId,
    { loading: regInvitationsLoading, error: regInvitationsError },
  ] = useRegistrationInvitationsByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [
    getTeamPlayerByUserId,
    {
      data: teamPlayerByUserId,
      loading: teamPlayerLoading,
      error: teamPlayerError,
    },
  ] = useTeamPlayerByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    getEmailLogsByUser,
    { data: emailLogsByUser, loading: emailLogsLoading, error: emailLogsError },
  ] = useGetEmailLogsByUserLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [
    getCreditsByUserId,
    { data: creditsByUserId, loading: creditsLoading, error: creditsError },
  ] = useCreditsByUserIdLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCredits(data.creditsByUserId);
    },
  });

  const { data: dataShirtSize, loading: loadingShirtSize } =
    useShirtSizeAllQuery({
      onCompleted: (data) => {
        setShirtSizes(data.shirtSizeAll);
      },
    });

  const [
    getEmailLogsMailgunByUser,
    {
      data: emailLogsMailgunByUser,
      loading: emailLogsMailgunLoading,
      error: emailLogsMailgunError,
    },
  ] = useGetEmailLogsMailgunByUserLazyQuery({
    fetchPolicy: "no-cache",
  });

  const retrieveExistingCart = async (userId: string) => {
    await getAllCartsByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        if (!data.getAllShoppingCartsByUserId) {
          setShoppingCarts([]);
        } else {
          setShoppingCarts(data.getAllShoppingCartsByUserId);
        }
      },
    });
  };

  const retrieveRegistrations = async (userId: string) => {
    await getRegistrationsByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        setRegistrations((prevState) =>
          [...prevState, ...data.registrationByUserId].sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : 1
          )
        );
      },
    });
    await getTournamentRegistrationsByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        setRegistrations((prevState) =>
          [...prevState, ...data.tournamentRegistrationsByUserId].sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : 1
          )
        );
      },
    });
    await getRegInvitationsByUserId({
      variables: {
        userId: userId,
      },
      onCompleted: (data) => {
        if (data.registrationInvitationsByUserId) {
          setRegistrationInvitations(data.registrationInvitationsByUserId);
        }
      },
    });
  };

  const retrieveTeams = async (userId: string) => {
    await getTeamPlayerByUserId({
      variables: { userId: userId },
      onCompleted: (data) => {
        if (!data.teamPlayerByUserId) {
          setTeams([]);
        } else {
          setTeams(data.teamPlayerByUserId);
        }
      },
    });
  };

  const retrieveEmailLogs = async (email: string) => {
    getEmailLogsByUser({
      variables: { email: email, pageSize: pageSizeDropdown, page: 0 },
    });
    getEmailLogsMailgunByUser({ variables: { email: email } });
  };

  const handleChangePaginationMailgun = (value: number) => {
    setPaginationMailgun({
      pageCount: paginationMailgun?.pageCount,
      pageSize: paginationMailgun?.pageSize,
      page: value - 1,
    });
  };
  const handleChangePaginationEmail = (value: number) => {
    setPaginationEmail({
      pageCount: paginationEmail?.pageCount,
      pageSize: paginationEmail?.pageSize,
      page: value - 1,
    });
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const handlePreviewEmail = async (body: string) => {
    setOpenModal(true);
    setSelectedEmail({ body: body });
  };

  const handleSelectedOption = (
    item: RegistrationInvitationsByUserIdQuery["registrationInvitationsByUserId"][0],
    idx: number
  ) => {
    if (idx === 1) {
      // Remove
      setOpenRegInviteModal({
        open: true,
        regInvite: item,
        type: "remove",
      });
    }
    if (idx === 2) {
      // Show Reg Invite URL
      setOpenRegInviteModal({
        open: true,
        regInvite: item,
        type: "regInviteURL",
      });
    }
  };

  const CART_COLUMNS: Column<
    GetAllShoppingCartsByUserIdQuery["getAllShoppingCartsByUserId"][0]
  >[] = [
    {
      Header: "Id#",
      accessor: (d) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${d.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {d.id}
          </a>
        );
      },
    },
    {
      Header: "Created At",
      id: "createdAt",
      accessor: (d) => {
        return <div>{dayjs(d.createdAt).format("YYYY-MM-DD hh:ss")}</div>;
      },
    },
    {
      Header: "Last Active",
      id: "lastActive",
      accessor: (d) => {
        return <div>{dayjs(d.lastActive).format("YYYY-MM-DD hh:ss")}</div>;
      },
    },
    {
      Header: "Status",
      accessor: (d) => {
        return <div>{d.shoppingCartStatus?.name}</div>;
      },
    },
    {
      Header: "Transaction Amount",
      accessor: (d) => {
        // sum of all transaction amounts
        let shoppingCartTransactionTotal = 0;
        d.shoppingCartTransactions.forEach((cartTransaction) => {
          const isPurchase =
            (cartTransaction.transactionType.name.includes("Purchase") ||
              cartTransaction.transactionType.name === "League Transfer In") &&
            cartTransaction.success;
          const isCreditPurchase =
            cartTransaction.transactionType.name === "Credit Purchase";

          if (cartTransaction.success) {
            shoppingCartTransactionTotal += isPurchase
              ? cartTransaction.transactionAmount
              : -cartTransaction.transactionAmount;
          }
        });
        return (
          <div>
            {numberToMoney(shoppingCartTransactionTotal)}
            {/* {d.shoppingCartTransactions.reduce((acc, item) => {
              return acc + item.transactionAmount;
            }, 0)} */}
          </div>
        );
      },
    },
  ];
  const REGISTRATION_COLUMNS: Column<
    | RegistrationByUserIdQuery["registrationByUserId"][0]
    | TournamentRegistrationsByUserIdQuery["tournamentRegistrationsByUserId"][0]
  >[] = [
    {
      Header: "Id#",
      accessor: "id",
    },
    {
      Header: "Created At",
      accessor: (d) => {
        return dayjs(d.createdAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      Header: "Region",
      accessor: (d) => {
        if (d.__typename === "TournamentRegistration") {
          return d.tournament.sportFormat.region.name;
        } else if (d.__typename === "Registration") {
          return d.session.league.sportFormat.region.name;
        }
        return "";
      },
    },
    {
      Header: "Reg Type",
      accessor: (d) => {
        if (d.__typename === "TournamentRegistration") {
          return d.registrationType.type;
        } else if (d.__typename === "Registration") {
          return d.registrationType.type;
        }
        return "";
      },
    },
    {
      Header: "Team Name",
      accessor: (d) => {
        if (d.__typename === "TournamentRegistration") {
          return "N/A";
        } else if (d.__typename === "Registration") {
          return d.team?.name;
        }
        return "";
      },
    },
    {
      Header: "Day",
      accessor: (d) => {
        if (d.__typename === "TournamentRegistration") {
          return DayOfWeek[d.tournament.dayOfWeek];
        } else if (d.__typename === "Registration") {
          return DayOfWeek[d.session.dayOfWeek];
        }
        return "";
      },
    },
    {
      Header: "Name",
      accessor: (d) => {
        if (d.__typename === "TournamentRegistration") {
          return d.tournament.name;
        } else if (d.__typename === "Registration") {
          return d.session.league.name;
        }
        return "";
      },
    },
    {
      Header: "Season",
      accessor: (d) => {
        if (d.__typename === "TournamentRegistration") {
          return "N/A";
        } else if (d.__typename === "Registration") {
          return d.session.registrationBatch.name;
        }
        return "";
      },
    },
    {
      Header: "Shopping Cart Id",
      accessor: (d) => {
        const shoppingCartId =
          d.__typename === "TournamentRegistration"
            ? d.shoppingCartId
            : d.__typename === "Registration"
            ? d.shoppingCart.id
            : null;
        if (!shoppingCartId) {
          return "N/A";
        }
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${shoppingCartId}`}
            target="_blank"
            rel="noreferrer"
          >
            {shoppingCartId}
          </a>
        );
      },
    },
    {
      Header: "Amount",
      accessor: (d) => {
        return `$${d.shoppingCartItem.amountPaid.toFixed(2)}`;
      },
    },
  ];

  const REGISTRATION_INVITATIONS_COLUMNS: Column<
    RegistrationInvitationsByUserIdQuery["registrationInvitationsByUserId"][0]
  >[] = [
    {
      Header: "Id#",
      accessor: "id",
    },
    {
      Header: "Created At",
      accessor: (d) => {
        return dayjs(d.createdAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      Header: "Region",
      accessor: (d) => {
        return d.session?.league.sportFormat.region.name;
      },
    },
    {
      Header: "Reg Type",
      accessor: (d) => {
        return d.productType.name;
      },
    },

    {
      Header: "Day",
      accessor: (d) => {
        return d.session?.dayOfWeekType.name;
      },
    },
    {
      Header: "Name",
      accessor: (d) => {
        return d.session?.league.name;
      },
    },
    {
      Header: "Season",
      accessor: (d) => {
        return d.session?.registrationBatch.name;
      },
    },
    {
      Header: "Discount Reason",
      accessor: (d) => {
        return d.refundReason.name;
      },
    },
    {
      Header: "Invitation Status",
      accessor: (d) => {
        return d.invitationStatus.name;
      },
    },
    {
      // Delete
      Header: "Actions",
      Cell: (d: CellParam) => {
        const options = [];
        if (d.row.original.invitationStatus.name === "Active") {
          options.push({ id: 1, text: "Remove" });
          options.push({ id: 2, text: "Reg Invite URL" });
        }
        return (
          <div>
            {d.row.original.id && options.length > 0 && (
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <MoreVerticalIcon />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="flex flex-col gap-1">
                  {options.map((options) => {
                    return (
                      <DropdownMenuItem
                        className="hover:bg-gray-100"
                        onClick={() => {
                          handleSelectedOption(d.row.original, options.id);
                        }}
                      >
                        {options.text}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        );
      },
    },
  ];

  const TEAM_COLUMNS: Column<
    TeamPlayerByUserIdQuery["teamPlayerByUserId"][0]
  >[] = [
    {
      Header: "Id#",
      accessor: (d) => {
        return <Body2>{d.id}</Body2>;
      },
    },
    {
      Header: "Created At",
      accessor: (d) => {
        return <Body2>{dayjs(d.createdAt).format("MMM D, YYYY h:mm A")}</Body2>;
      },
    },
    {
      Header: "User Team",
      accessor: (d) => {
        return (
          <Body2>
            <a
              className="underline cursor-pointer text-info-50"
              href={`${baseURL}/teams/team-info/${d.team.id}?userId=${params.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {d?.team?.name}
            </a>
          </Body2>
        );
      },
    },
    {
      Header: "Team HQ",
      accessor: (d) => {
        return (
          <Button
            variant="secondary"
            href={`${baseAdminURL}/players/team/${d.team.id}`}
          >
            <GroupOutlinedIcon />
          </Button>
        );
      },
    },
    {
      Header: "Region",
      accessor: (d) => {
        return <Body2>{d.team.session.region.name}</Body2>;
      },
    },
    {
      Header: "Reg Batch",
      accessor: (d) => {
        return <Body2>{d.team.session.registrationBatch.name}</Body2>;
      },
    },
    {
      Header: "League",
      accessor: (d) => {
        return <Body2>{d.team.session.league.name}</Body2>;
      },
    },
    {
      Header: "Day",
      accessor: (d) => {
        return <Body2>{DayOfWeek[d.team.session.dayOfWeek]}</Body2>;
      },
    },
    {
      Header: "Team Type",
      accessor: (d) => {
        return <Body2>{d.team.isIndyTeam ? "Free Agent" : "Team"}</Body2>;
      },
    },
    {
      Header: "Player Role",
      accessor: (d) => {
        return <Body2>{d.teamPlayerRole.teamPlayerRoleName}</Body2>;
      },
    },
  ];

  const EMAIL_COLUMNS_MAILGUN: Column<any>[] = [
    {
      Header: "Date",
      accessor: (d: any) => {
        return dayjs(d.createdAt).format("MMM D, YYYY h:mm A");
      },
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Clicked",
      accessor: "clicked",
    },
    {
      Header: "Opened",
      accessor: "opened",
    },
  ];

  const CREDIT_COLUMNS: Column<CreditsByUserIdQuery["creditsByUserId"][0]>[] = [
    {
      Header: "Id",
      accessor: (d) => {
        return <Body2>{d.id}</Body2>;
      },
      Footer: "Totals",
    },
    {
      Header: "Created At",
      accessor: (d) => {
        return <Body2>{dayjs(d.createdAt).format("YYYY-MM-DD")}</Body2>;
      },
    },
    {
      Header: "Created By",
      accessor: (d) => {
        return (
          <Body2>
            {d.createdByUser.firstName} {d.createdByUser.lastName}
          </Body2>
        );
      },
    },
    {
      Header: "Credit Type",
      accessor: (d) => {
        return <Body2>{d.creditType.name}</Body2>;
      },
    },
    {
      Header: "Amount",
      accessor: (d) => {
        return <Body2>{numberToMoney(d.amount)}</Body2>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.amount, 0),
          [rows]
        );

        const formattedTotal = numberToMoney(total);

        return <div>{formattedTotal}</div>;
      },
    },
    {
      Header: "Current Balance",
      accessor: (d) => {
        return <Body2>{numberToMoney(d.currentBalance)}</Body2>;
      },
      Footer: ({ rows }) => {
        const total = React.useMemo(
          () => rows.reduce((sum, row) => sum + row.original.currentBalance, 0),
          [rows]
        );

        const formattedTotal = numberToMoney(total);

        return <div>{formattedTotal}</div>;
      },
    },
    {
      Header: "Expiry Date",
      accessor: (d) => {
        return <Body2>{dayjs(d.expiryDate).format("YYYY-MM-DD")}</Body2>;
      },
    },
    {
      Header: "Shopping Cart Item Id",
      accessor: (d) => {
        if (!d.shoppingCart) {
          return <Body2>N/A</Body2>;
        }
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${d.shoppingCart.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {d.shoppingCartItemId}
          </a>
        );
      },
    },
    {
      Header: "Description",
      accessor: (d) => {
        return (
          <div className="flex flex-row items-start justify-start">
            <Dialog>
              <DialogTrigger
                asChild
                disabled={d.description === ""}
              >
                <IconButton>
                  <GradingOutlinedIcon
                    className={
                      d.description === ""
                        ? "text-neutral-60"
                        : "text-primary-30"
                    }
                  />
                </IconButton>
              </DialogTrigger>
              <DialogContent className="w-1/2 max-w-full">
                <DialogHeader>
                  <DialogTitle>Credit Description</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col w-full gap-4">
                    <Body1 className="break-all">{d.description}</Body1>
                  </div>
                </DialogDescription>
                <DialogClose>
                  <Button variant={"negative"}>Close</Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: (d) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger disabled={d.currentBalance <= 0}>
              <MoreVerticalIcon
                className={cn(
                  d.currentBalance <= 0
                    ? "text-gray-500 cursor-not-allowed"
                    : "hover:text-gray-300"
                )}
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="flex flex-col gap-1">
              <Dialog key={d.id}>
                <DialogTrigger>
                  <DropdownMenuItem
                    onSelect={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Body1>Transfer Credit</Body1>
                  </DropdownMenuItem>
                </DialogTrigger>
                <DialogContent className="w-1/2 max-w-full">
                  <DialogHeader>
                    <DialogTitle>Transfer Credit</DialogTitle>
                  </DialogHeader>
                  <DialogDescription>
                    <div className="flex flex-col w-full gap-4">
                      <FormFieldPlayerSeach
                        ignoreUserIds={[d.userId]}
                        onUserSelect={(user) => {
                          setCreditTransfer((prevState) => ({
                            ...prevState,
                            toUserName: user.firstName + " " + user.lastName,
                            toUserEmail: user.email,
                            fromUserName:
                              d.user.firstName + " " + d.user.lastName,
                            fromUserEmail: d.user.email,
                            toUserId: user.id,
                            fromUserId: d.userId,
                            amount: d.currentBalance,
                            originalCreditId: d.id,
                          }));
                        }}
                      />
                      <FormField
                        initialValue={d.currentBalance.toFixed(2)}
                        inputChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setCreditTransfer((prevState) => {
                            if (prevState) {
                              return {
                                ...prevState,
                                amount: +e.target.value,
                              };
                            } else {
                              return undefined;
                            }
                          });
                        }}
                        type="number"
                        name="credit transfer amount"
                        placeholder="0.00"
                        className="w-2/3 h-9"
                        max={d.currentBalance}
                      />
                      {creditTransfer && (
                        <div className="flex flex-col gap-1">
                          <Body1>New Credit Details</Body1>
                          <Caption1>
                            To Name: {creditTransfer.toUserName}
                          </Caption1>
                          <Caption1>
                            To Email: {creditTransfer.toUserEmail}
                          </Caption1>
                          <Caption1>
                            Amount: ${creditTransfer.amount.toFixed(2)}
                          </Caption1>
                          <Body1>Original Credit Details</Body1>
                          <Caption1>
                            From Name: {creditTransfer.fromUserName}
                          </Caption1>
                          <Caption1>
                            From Email: {creditTransfer.fromUserEmail}
                          </Caption1>
                          <Caption1>
                            Remaining Balance: $
                            {(d.currentBalance - creditTransfer.amount).toFixed(
                              2
                            )}
                          </Caption1>
                          <div className="flex flex-row gap-4">
                            <DialogClose>
                              <Button variant={"negative"}>Close</Button>
                            </DialogClose>
                            <DialogClose>
                              <Button
                                disabled={
                                  creditTransfer.amount <= 0 ||
                                  creditTransfer.amount > d.currentBalance
                                }
                                variant={
                                  creditTransfer.amount <= 0 ||
                                  creditTransfer.amount > d.currentBalance
                                    ? "disabled"
                                    : "primary"
                                }
                                onClick={() => {
                                  CreateCreditTransfer({
                                    variables: {
                                      createCreditTransferInput: {
                                        amount: creditTransfer.amount,
                                        fromUserId: creditTransfer.fromUserId,
                                        toUserId: creditTransfer.toUserId,
                                        originalCreditId:
                                          creditTransfer.originalCreditId,
                                      },
                                    },
                                    onCompleted: (data) => {
                                      if (data.createCreditTransfer.success) {
                                        dispatch(
                                          displayAlertSuccess(
                                            data.createCreditTransfer.message
                                          )
                                        );
                                      } else {
                                        dispatch(
                                          displayAlertError(
                                            data.createCreditTransfer.message
                                          )
                                        );
                                      }
                                    },
                                    onError: (error) => {
                                      dispatch(
                                        displayAlertError(
                                          error.message ??
                                            "Error: Credit transfer failed"
                                        )
                                      );
                                    },
                                    refetchQueries: [
                                      ListAllOperations.Query.CreditsByUserId,
                                    ],
                                  });
                                }}
                              >
                                Confirm
                              </Button>
                            </DialogClose>
                          </div>
                        </div>
                      )}
                    </div>
                  </DialogDescription>
                </DialogContent>
              </Dialog>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const EMAIL_COLUMNS: Column<any>[] = [
    {
      Header: "Date",
      accessor: (d: any) => {
        return dayjs(d.createdAt).format("MMM D, YYYY h:mm A");
      },
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Options",
      accessor: (d: any) => {
        return (
          <Button
            variant="secondary"
            onClick={() => handlePreviewEmail(d.body)}
          >
            Preview
          </Button>
        );
      },
    },
  ];

  const start = (paginationMailgun.page || 0) * pageSize;
  const end = start + pageSize;
  const cartData = useMemo(
    () => (shoppingCarts ? shoppingCarts : []),
    [shoppingCarts]
  );
  const registrationColumns = useMemo(() => REGISTRATION_COLUMNS, []);
  const registrationData = useMemo(() => registrations, [registrations]);
  const regInvitationsColumns = useMemo(
    () => REGISTRATION_INVITATIONS_COLUMNS,
    []
  );
  const regInvitationsData = useMemo(
    () => registrationInvitations,
    [registrationInvitations]
  );
  const teamData = useMemo(() => teams, [teams]);
  const creditData = useMemo(() => credits, [credits]);
  const emailColumnsMailgun = useMemo(() => EMAIL_COLUMNS_MAILGUN, []);
  const emailColumns = useMemo(() => EMAIL_COLUMNS, []);
  const emailsMailgunData = useMemo(
    () =>
      emailLogsMailgunByUser
        ? emailLogsMailgunByUser.getEmailLogsMailgunByUser.mailgun.slice(
            start,
            end
          )
        : [],
    [emailLogsMailgunByUser, paginationMailgun]
  );
  const emailsData = useMemo(
    () => (emailLogsByUser ? emailLogsByUser.getEmailLogsByUser.email : []),
    [emailLogsByUser, paginationEmail]
  );

  const cartTable = (
    <BaseTable
      columns={CART_COLUMNS}
      data={cartData}
    />
  );

  const isPemissionGranted =
    admin && admin.permission
      ? isPermissionGranted(
          admin.permission,
          EPermission["REGISTRATION_FREELOADER"]
        )
      : false;

  let permittedRegions:
    | {
        id: number;
        name: string;
      }[]
    | undefined = undefined;

  if (isPemissionGranted)
    permittedRegions =
      admin && admin.permission
        ? filterPermittedRegions(
            admin.permission,
            EPermission["REGISTRATION_FREELOADER"]
          )?.map((region) => {
            return {
              id: region.id,
              name: region.name,
            };
          })
        : undefined;

  const registrationTable = (
    <div className="flex flex-col gap-3">
      <Button
        variant="primary"
        onClick={() => {
          setOpenWaitlistDialog(true);
        }}
        width="1/4"
      >
        Add To Waitlist
      </Button>
      <Button
        variant={isPemissionGranted ? "primary" : "disabled"}
        onClick={() => {
          setOpenAvailableSessionsDialog(true);
        }}
        width="1/4"
        disabled={!isPemissionGranted}
      >
        <div className="flex justify-center gap-2">
          Free Loaders
          {!isPemissionGranted && <LockOutlined />}
        </div>
      </Button>
      {registrationInvitations && (
        <div className="flex flex-col gap-3">
          <Headline2Variable>Registrations Invitations</Headline2Variable>
          <BaseTable
            data={regInvitationsData}
            columns={regInvitationsColumns}
          />
        </div>
      )}
      <Headline2Variable>Registrations</Headline2Variable>
      <BaseTable
        data={registrationData}
        columns={registrationColumns}
      />
    </div>
  );

  const emailsMailgunTable = (
    <BaseTable
      data={emailsMailgunData}
      columns={emailColumnsMailgun}
      pagination={paginationMailgun}
      handleChangePagination={handleChangePaginationMailgun}
    />
  );

  const emailsTable = (
    <BaseTable
      data={emailsData}
      columns={emailColumns}
      pagination={paginationEmail}
      handleChangePagination={handleChangePaginationEmail}
      showPagination={false}
    />
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdateUser = () => {
    if (!user.firstName || !user.lastName || !playerUserId) {
      dispatch(displayAlertError("Error: Please fill out all required fields"));
      return;
    }

    const permissions =
      user.permission &&
      user.permission.map((perm) => {
        return {
          id: perm.id ? perm.id : null,
          permissionId: perm.permission.id,
          regionId: perm.region ? perm.region.id : 0,
        };
      });

    UpdateUser({
      variables: {
        updateUserId: playerUserId,
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          genderIdentityId: user.genderIdentityId,
          birthDate: dayjs(user.birthDate).format("YYYY-MM-DD") ?? undefined,
          phoneNumber: user.phoneNumber,
          isStaff: user.isStaff ?? false,
          isAdmin: user.isAdmin ?? false,
          marketingEmail: user.marketingEmail,
          permission: permissions,
          privacyGender: user.privacyGender,
          privacyPhoneNumber: user.privacyPhoneNumber,
          regionId: user.regionId,
          shirtSizeId: user.shirtSizeId,
        },
      },
      refetchQueries: [ListAllOperations.Query.UserByIdGet],
      onCompleted: (data) => {
        if (data.updateUser) {
          dispatch(displayAlertSuccess("User updated successfully"));
        } else {
          dispatch(displayAlertError("Error: User not updated"));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
    return;
  };

  const { loading: loadingUserInfo } = useUserByIdGetQuery({
    variables: {
      userByIdGetId: playerUserId!,
    },
    skip: playerUserId === "",
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: "cache-first",
    onCompleted: (data) => {
      setUser({
        ...data.userByIdGet,
      });
    },
  });

  useEffect(() => {
    if (tabGroupValue === "Cart" && playerUserId) {
      retrieveExistingCart(playerUserId);
    } else if (tabGroupValue === "Registration" && playerUserId) {
      retrieveRegistrations(playerUserId);
    } else if (tabGroupValue === "Team" && playerUserId) {
      retrieveTeams(playerUserId);
    } else if (tabGroupValue === "Email Logs" && playerUserId && user.email) {
      retrieveEmailLogs(user.email);
    } else if (tabGroupValue === "Credit" && playerUserId) {
      getCreditsByUserId({ variables: { userId: playerUserId } });
    }
  }, [dispatch, tabGroupValue]);

  useEffect(() => {
    dispatch(getAllPermissions());
    dispatch(getPrivacySettings({}));
  }, []);

  // useEffect(() => {
  //   if (emailLogsByUser && emailLogsByUser.getEmailLogsByUser) {
  //     setPaginationEmail({
  //       pageCount: Math.ceil(
  //         emailLogsByUser.getEmailLogsByUser.count / pageSize
  //       ),
  //       pageSize: pageSizeDropdown,
  //       page: 0,
  //     });
  //   }
  // }, [emailLogsByUser]);

  useEffect(() => {
    if (
      emailLogsMailgunByUser &&
      emailLogsMailgunByUser.getEmailLogsMailgunByUser
    ) {
      setPaginationMailgun({
        pageCount: Math.ceil(
          emailLogsMailgunByUser.getEmailLogsMailgunByUser.mailgun.length /
            pageSize
        ),
        pageSize: pageSize,
        page: 0,
      });
    }
  }, [emailLogsMailgunByUser]);

  useEffect(() => {
    if (user.email) {
      getEmailLogsByUser({
        variables: {
          email: user.email,
          pageSize: paginationEmail.pageSize,
          page: paginationEmail.page,
        },
      });
    }
  }, [paginationEmail.pageSize]);

  if (iframeRef.current && iframeRef) {
    const formattedBody = `${selectedEmail.body.replace(
      /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
      (_: any, link: string, text: string) => {
        return emailButton(link, text);
      }
    )}`;
    const blob = new Blob([formattedBody], { type: "text/html" });
    iframeRef.current.src = URL.createObjectURL(blob);
  }

  useEffect(() => {
    if (selectedEmail.body && openModal) {
      iframeRef.current?.focus();
      setTimeout(() => {
        if (iframeRef.current) {
          const formattedBody = `${selectedEmail.body.replace(
            /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
            (_: any, link: string, text: string) => {
              return emailButton(link, text);
            }
          )}`;
          const blob = new Blob([formattedBody], { type: "text/html" });
          iframeRef.current.src = URL.createObjectURL(blob);
        }
      }, 1000);
    }
  }, [selectedEmail, openModal]);

  const handleFreeLoaderRegistration = async () => {
    if (confirmDirectReg.session) {
      await createFreeLoaderRegInvite({
        variables: {
          freeLoaderRegInviteInput: {
            productId: confirmDirectReg.session.id,
            userId: playerUserId || "",
            productTypeId: confirmDirectReg.session.productType,
            refundReasonId: confirmDirectReg.discountType,
          },
        },
        onCompleted: (data) => {
          if (data.createFreeLoaderRegInvitation.success) {
            dispatch(displayAlertSuccess("Free Loader Registration Created"));
            setOpenAvailableSessionsDialog(false);
            setConfirmDirectReg((prevState) => ({
              ...prevState,
              regInviteUrl:
                data.createFreeLoaderRegInvitation.regInviteURL || "",
            }));
            retrieveRegistrations(playerUserId || "");
          } else {
            dispatch(
              displayAlertError("Error: Free Loader Registration Failed")
            );
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  };

  if (loading || isLoading || loadingFreeLoaderReg || loadingRemoveRegInvite) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }
  return (
    <>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/venues"
      ></BreadCrumbs>
      <div className="flex items-center justify-between h-16 mt-5">
        <div>
          <Headline1Variable>{`${
            user.firstName + " " + user.lastName + " (" + user.id + ")"
          } `}</Headline1Variable>
        </div>
        <div className="flex flex-row items-center gap-4 md:max-2xl:justify-end:items-end:self-end:content-end:justify-self-end">
          {/* <Button variant="primary" onClick={handleUpdateUser} type="submit">
            Save
          </Button> */}
        </div>
      </div>
      <div className="flex w-[1000px] pb-6">
        <TabGroup
          content={[
            "Info",
            "Cart",
            "Registration",
            "Team",
            "Email Logs",
            admin?.isSuperAdmin ? "Permission" : "",
            "Credit",
            "Notification",
          ]}
          inputChange={(value) => {
            setTabGroupValue(value);
          }}
          value={tabGroupValue}
          className="max-w-full w-fit"
        />
      </div>
      {tabGroupValue === "Info" && (
        <div className="flex flex-col gap-5">
          {(!user.phoneNumber || !user.birthDate) && (
            <Disclaimer
              variant="warning"
              content="Some fields are missing."
              size="large"
              persist={true}
              mt={"mt-1"}
            />
          )}
          <Card>
            {/* Row 1: FirstName, LastName and Gender */}
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                className="border-neutral-20 bg-neutral-60"
                initialValue={user.email ? user.email : ""}
                inputChange={() => {}}
                assistiveText={
                  user.emailChange
                    ? `Pending change to ${user.emailChange}`
                    : ""
                }
                name="email"
                label="Email"
                disabled
              />
              <FormField
                initialValue={user.firstName ? user.firstName : ""}
                inputChange={handleChange}
                name="firstName"
                label="First Name"
              />
              <FormField
                initialValue={user.lastName ? user.lastName : ""}
                inputChange={handleChange}
                name="lastName"
                label="Last Name"
              />
            </div>
          </Card>
          <Card>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                value={
                  user.genderIdentityId ? user.genderIdentityId.toString() : "0"
                }
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    genderIdentityId: +value,
                  }));
                }}
                name="genderIdentity"
                label="Gender"
                placeholder="Gender"
              >
                {[
                  { id: 0, name: "Select a gender" },
                  ...[
                    { id: 1, name: "Man" },
                    { id: 2, name: "Woman" },
                    { id: 3, name: "Nonbinary" },
                    { id: 4, name: "Trans Man" },
                    { id: 5, name: "Trans Woman" },
                    { id: 6, name: "Genderqueer" },
                    { id: 7, name: "Other" },
                    { id: 8, name: "Prefer not to say" },
                  ],
                ]}
              </FormFieldSelect>
              <FormFieldDate
                label="Birth Date"
                initialValue={
                  user.birthDate ? dayjs(user.birthDate).toDate() : undefined
                }
                dateChange={(date) => {
                  setUser((prevState) => ({
                    ...prevState,
                    birthDate: dayjs(date).format("YYYY-MM-DD"),
                  }));
                }}
              />
              <FormField
                onWheel={(e) => e.preventDefault()}
                initialValue={user.phoneNumber ? user.phoneNumber : ""}
                inputChange={handleChange}
                name="phoneNumber"
                label="Phone Number"
              />
            </div>
            <div className="grid grid-cols-1 gap-6 mt-5 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                value={user.regionId ? user.regionId.toString() : "0"}
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    regionId: +value,
                  }));
                }}
                name="regionId"
                label="Region"
                placeholder="Region"
              >
                {[{ id: 0, name: "Select a region" }, ...selectedRegions]}
              </FormFieldSelect>
              <FormFieldSelect
                value={user.shirtSizeId ? user.shirtSizeId.toString() : "0"}
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    shirtSizeId: +value,
                  }));
                }}
                name="shirtSizeId"
                label="Shirt Size"
                placeholder="Shirt Size"
              >
                {[{ id: 0, name: "Select a Shirt Size" }, ...shirtSizes]}
              </FormFieldSelect>
            </div>
          </Card>
          <Card>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                value={user.privacyGender ? user.privacyGender.toString() : "0"}
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    privacyGender: +value,
                  }));
                }}
                name="privacyGender"
                label="Privacy Gender"
                placeholder="Privacy Gender"
              >
                {privacySettings}
              </FormFieldSelect>
              <FormFieldSelect
                value={
                  user.privacyPhoneNumber
                    ? user.privacyPhoneNumber.toString()
                    : "0"
                }
                inputChange={(value) => {
                  setUser((prevState) => ({
                    ...prevState,
                    privacyPhoneNumber: +value,
                  }));
                }}
                name="privacyPhoneNumber"
                label="Privacy Phone Number"
                placeholder="Privacy Phone Number"
              >
                {privacySettings}
              </FormFieldSelect>
            </div>
          </Card>
          <Card>
            <div className="flex gap-4">
              <FormFieldViewOnly
                label="Created At"
                text={dayjs(user.createdAt).format("YYYY-MM-DD")}
                className="h-10 max-w-52 w-52"
                containerClassName="w-fit"
                disabled={true}
              />
              <FormFieldViewOnly
                label="Updated At"
                text={dayjs(user.updatedAt).format("YYYY-MM-DD")}
                className="h-10 max-w-52 w-52"
                containerClassName="w-fit"
                disabled={true}
              />
              <FormFieldViewOnly
                label="Last Login"
                text={
                  user.lastLogin
                    ? dayjs(user.lastLogin).format("YYYY-MM-DD")
                    : "User has never logged in"
                }
                className="h-10 max-w-52 w-52"
                containerClassName="w-fit"
                disabled={true}
              />
            </div>
          </Card>
          <Card>
            <CheckBox
              label="Staff Permission"
              defaultChecked={false}
              checkedState={user.isStaff}
              inputChange={(val) => {
                setUser((prevState) => ({
                  ...prevState,
                  isStaff: val,
                }));
              }}
              id="isStaff"
            />
          </Card>
          <div className="flex flex-row-reverse mt-6">
            <Button
              variant="primary"
              onClick={handleUpdateUser}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {tabGroupValue === "Cart" && (
        <div className="mt-3">
          {cartLoading ? <LoadingMaterialUI /> : cartTable}
        </div>
      )}
      {tabGroupValue === "Registration" && (
        <div className="mt-3">
          {registrationLoading ? <LoadingMaterialUI /> : registrationTable}
        </div>
      )}
      {tabGroupValue === "Team" && (
        <div className="mt-3">
          {teamPlayerLoading ? (
            <LoadingMaterialUI />
          ) : (
            <div className="flex flex-col gap-3">
              <a
                href={`${baseURL}/schedule?userId=${playerUserId}`}
                target="_blank"
              >
                <Button
                  variant="primary"
                  width="1/4"
                >
                  View Schedule
                </Button>
              </a>
              <BaseTable
                data={teamData}
                columns={TEAM_COLUMNS}
              />
            </div>
          )}
        </div>
      )}
      {tabGroupValue === "Permission" && admin.isSuperAdmin && (
        <div>
          <Card className="mt-3">
            <div className="flex flex-col gap-4 md:items-start">
              <CheckBox
                label="Admin Permission"
                defaultChecked={false}
                checkedState={user.isAdmin}
                inputChange={(val) => {
                  setUser((prevState) => ({
                    ...prevState,
                    isAdmin: val,
                  }));
                }}
                id="isAdmin"
              />
            </div>
          </Card>
          <Card className="mt-5">
            <Subtitle1>Permission - Region</Subtitle1>
            {user.permission.length === 0 && (
              <Subtitle1>No existing Permission</Subtitle1>
            )}
            <div className="flex flex-col">
              {user.permission.map((permission, index: number) => {
                return (
                  <div
                    className="flex flex-row mt-3"
                    key={index}
                  >
                    {permission.id !== 0 ? (
                      <div className="flex flex-row items-center justify-center gap-4">
                        <Subtitle1>{`${permission.permission.name} - ${
                          permission.region ? permission.region.name : "All"
                        }`}</Subtitle1>
                        <div className="max-w-1/2">
                          <MuiButton
                            sx={{
                              padding: "0px",
                              minWidth: "0px",
                              minHeight: "0px",
                              width: "24px",
                              height: "24px",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            <CloseIcon
                              className="text-error-30 hover:text-error-10"
                              onClick={(e) => {
                                setUser((prevState) => ({
                                  ...prevState,
                                  permission: prevState.permission.filter(
                                    (_, i) => i !== index
                                  ),
                                }));
                              }}
                            />
                          </MuiButton>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center justify-center w-1/2 gap-4">
                        <FormFieldSelect
                          value={
                            user.permission.length - 1 >= index
                              ? user.permission[index].permission.id.toString()
                              : "0"
                          }
                          name="permission"
                          placeholder="All"
                          inputChange={(value: string) => {
                            setUser((prevState) => {
                              const permission = permissions.find(
                                (permission: any) => permission.id === +value
                              );
                              if (permission) {
                                const curPermissions = prevState.permission;
                                curPermissions[index].permission = permission;
                                return {
                                  ...prevState,
                                  permission: curPermissions,
                                };
                              }
                              return prevState;
                            });
                          }}
                          label="Permission"
                        >
                          {[
                            { id: "0", name: "Select Permission" },
                            ...permissions,
                          ]}
                        </FormFieldSelect>
                        <FormFieldSelect
                          value={
                            user.permission.length - 1 >= index
                              ? user.permission[index].region?.id.toString() ??
                                "0"
                              : "0"
                          }
                          name="region"
                          placeholder="All"
                          inputChange={(value: string) => {
                            setUser((prevState) => {
                              const region = regions.find(
                                (region: any) => region.id === +value
                              );
                              if (region) {
                                const curPermissions = prevState.permission;
                                curPermissions[index].region = region;
                                return {
                                  ...prevState,
                                  permission: curPermissions,
                                };
                              }
                              return prevState;
                            });
                          }}
                          label="Region"
                        >
                          {[{ id: "0", name: "All" }, ...regions]}
                        </FormFieldSelect>
                        <MuiButton
                          sx={{
                            padding: "0px",
                            minWidth: "0px",
                            marginTop: "16px",
                            minHeight: "0px",
                            width: "24px",
                            height: "24px",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <CloseIcon
                            className="text-error-30 hover:text-error-10"
                            onClick={() => {
                              setUser((prevState) => ({
                                ...prevState,
                                permission: prevState.permission.filter(
                                  (_, i) => i !== index
                                ),
                              }));
                            }}
                          />
                        </MuiButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <Button
              className="mt-5"
              variant="primary"
              onClick={() => {
                setUser((prevState) => {
                  let newPerm: UserByIdGetQuery["userByIdGet"]["permission"][0] =
                    {
                      id: 0,
                      userId: user.id,
                      permission: {
                        id: 0,
                        name: "Select Permission",
                      },
                      region: {
                        id: 0,
                        name: "all",
                      },
                    };

                  return {
                    ...prevState,
                    permission: [...prevState.permission, newPerm],
                  };
                });
              }}
            >
              Add Permissions
            </Button>
          </Card>
          <div className="flex flex-row-reverse mt-6">
            <Button
              variant="primary"
              onClick={handleUpdateUser}
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {tabGroupValue === "Email Logs" && (
        <div>
          <div className="flex flex-col gap-3 mt-3">
            <Headline2Variable>
              Email Provider Logs (7 days history)
            </Headline2Variable>
            <Card>
              {emailLogsMailgunLoading ? (
                <LoadingMaterialUI />
              ) : (
                emailsMailgunTable
              )}
            </Card>
            <Headline2Variable>{`J.U.D.I. Logs`}</Headline2Variable>
            {/* dropdown for pagination 20, 100, All */}
            <div className="w-40">
              <FormFieldSelect
                value={
                  paginationEmail.pageSize
                    ? paginationEmail.pageSize.toString()
                    : "0"
                }
                name="rows"
                placeholder="All"
                inputChange={(value: string) => {
                  if (value === "0") {
                    setPaginationEmail((prev) => ({
                      ...prev,
                      pageSize: undefined,
                      page: 0,
                    }));
                  } else {
                    setPaginationEmail({
                      pageSize: +value,
                      page: 0,
                    });
                  }
                }}
                label="No. of Emails"
              >
                {[
                  {
                    id: pageSizeDropdown.toString(),
                    name: pageSizeDropdown.toString(),
                  },
                  { id: "100", name: "100" },
                  { id: "0", name: "All" },
                ]}
              </FormFieldSelect>
            </div>
            <Card>
              {emailLogsLoading ? <LoadingMaterialUI /> : emailsTable}
            </Card>
          </div>
        </div>
      )}
      {tabGroupValue === "Notification" && <Notification user={user} />}

      {tabGroupValue === "Credit" && (
        <div className="mt-3">
          {creditsLoading ? (
            <LoadingMaterialUI />
          ) : (
            <BaseTable
              data={creditData}
              columns={CREDIT_COLUMNS}
            />
          )}
        </div>
      )}
      <Dialog
        open={openModal}
        onOpenChange={(openState) => {
          if (!openState) handleCloseDialog();
        }}
      >
        <DialogContent className="max-w-[800px] max-h-[90%] h-[80%] overflow-scroll">
          <div className="flex flex-col justify-between w-full h-full gap-4">
            <iframe
              ref={iframeRef}
              title="Email Preview"
              style={{ width: "700px", height: "100%", border: "none" }}
            />
          </div>
        </DialogContent>
      </Dialog>

      <WaitlistSessionsDialog
        open={openWaitlistDialog}
        onOpenChange={(value) => setOpenWaitlistDialog(value)}
        userId={playerUserId || ""}
      />
      <AvailableSessionsDialog
        open={openAvailableSessionsDialog}
        onOpenChange={(value) => setOpenAvailableSessionsDialog(value)}
        userId={playerUserId || ""}
        onSessionSelect={(session) => {
          setConfirmDirectReg({
            open: true,
            session: session,
            discountType: 19,
          });
        }}
        title="Free Loader Registration"
        regions={permittedRegions}
      />
      <Dialog
        open={confirmDirectReg.open}
        onOpenChange={(openState) => {
          if (!openState)
            setConfirmDirectReg({ open: false, discountType: 19 });
        }}
      >
        <DialogContent className="max-w-[800px] max-h-[90%]">
          <DialogHeader>
            <Headline2Variable>Free Loader Registration</Headline2Variable>
          </DialogHeader>
          <div className="flex flex-col justify-between w-full h-full gap-2">
            <h5 className="mb-0">Session Details</h5>
            <div className="flex">
              <h6 className="w-1/3">League Name:</h6>
              <p className="">{confirmDirectReg.session?.leagueName}</p>
            </div>
            <div className="flex">
              <h6 className="w-1/3">Registration Type:</h6>
              <p className="">
                {confirmDirectReg.session?.productType === 1
                  ? "Free Agent"
                  : "Team"}
              </p>
            </div>
            <div className="flex">
              <h6 className="w-1/3">Start Date:</h6>
              <p className="">
                {dayjs(confirmDirectReg.session?.startDate).format(
                  "MM-DD-YYYY"
                )}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h6 className="w-1/3">Discount Type</h6>
              {refundReasons && (
                <FormFieldSelect
                  inputChange={(value) => {
                    setConfirmDirectReg((prev) => ({
                      ...prev,
                      discountType: +value,
                    }));
                  }}
                  label={"Choose Discount Type"}
                  placeholder="Select Discount Type"
                  value={confirmDirectReg.discountType.toString()}
                >
                  {[...refundReasons?.getDiscountRefundReasons]}
                </FormFieldSelect>
              )}
              {confirmDirectReg.regInviteUrl && (
                <div className="flex gap-3 mt-3">
                  Reg Invite Url:{" "}
                  <p className="text-cyan-700">
                    {confirmDirectReg.regInviteUrl}
                  </p>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        confirmDirectReg.regInviteUrl || ""
                      );
                    }}
                  >
                    <div className="text-sm">Copy To Clipboard</div>
                  </Button>
                </div>
              )}
            </div>
            <div className="flex justify-end gap-2">
              <Button
                variant="secondary"
                onClick={() => {
                  setOpenWaitlistDialog(false);
                  setConfirmDirectReg({ open: false, discountType: 19 });
                }}
              >
                Cancel
              </Button>
              {!confirmDirectReg.regInviteUrl && (
                <Button
                  variant="primary"
                  onClick={() => {
                    handleFreeLoaderRegistration();
                  }}
                >
                  Confirm
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openRegInviteModal.open}
        onOpenChange={(openState) => {
          if (!openState)
            setOpenRegInviteModal({ open: false, type: "remove" });
        }}
      >
        <DialogContent className="max-w-[800px] max-h-[90%]">
          <DialogHeader>
            <Headline2Variable>
              {openRegInviteModal.type === "remove"
                ? "Remove Registration Invitation"
                : "Registration Invitation URL"}
            </Headline2Variable>
          </DialogHeader>
          {openRegInviteModal.type === "remove" && (
            <div className="flex flex-col justify-between w-full h-full gap-2">
              <h5 className="mb-0">
                Are you sure you want to remove this registration invitation?
              </h5>
              <div className="flex justify-end gap-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenRegInviteModal({ open: false, type: "remove" });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    removeRegInvite({
                      variables: {
                        removeRegistrationInvitationId:
                          openRegInviteModal.regInvite?.id || 0,
                      },
                      onCompleted: (data) => {
                        if (data.removeRegistrationInvitation.success) {
                          dispatch(
                            displayAlertSuccess(
                              "Registration Invitation Removed"
                            )
                          );
                          setOpenRegInviteModal({
                            open: false,
                            type: "remove",
                          });
                          retrieveRegistrations(playerUserId || "");
                        } else {
                          dispatch(
                            displayAlertError(
                              "Error: Registration Invitation not removed"
                            )
                          );
                        }
                      },
                      onError: (error) => {
                        dispatch(displayAlertError(error.message));
                      },
                    });
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
          {openRegInviteModal.type === "regInviteURL" && (
            <div className="flex flex-col justify-between w-full h-full gap-2">
              <div className="flex justify-between gap-2">
                <p className="text-cyan-700">{`${baseURL}/registration-invitation/${openRegInviteModal.regInvite?.key}`}</p>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${baseURL}/registration-invitation/${openRegInviteModal.regInvite?.key}`
                    );
                  }}
                >
                  <div className="text-sm">Copy To Clipboard</div>
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Player;
