import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "../shadcn/dialog";
import Button from "../Button/Button";
import Headline2Variable from "../Text/Headline/Headline2Variable";
import ScheduleBackup from "../ScheduleBackup";

interface ScheduleHistoryDialogProps {
  schedule: any[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onRestoreSchedule: () => void;
}

/**
 * A dialog that allows the user to swap two teams
 * @param schedule Array of schedule rows
 * @param open Controls whether the dialog is open or not
 * @param onOpenChange Function to change the open state
 * @param onRestoreSchedule Function to restore the schedule
 * @returns
 */
const ScheduleHistoryDialog = ({
  schedule,
  open,
  onOpenChange,
  onRestoreSchedule,
}: ScheduleHistoryDialogProps) => {
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className="sm:max-w-[80%] max-h-[80%]">
        <DialogTitle>
          <Headline2Variable>Schedule Backup</Headline2Variable>
        </DialogTitle>

        <ScheduleBackup rows={schedule} />

        <DialogFooter>
          <DialogClose>
            <Button variant="secondary">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleHistoryDialog;
